import React, { useEffect } from "react";
import { useRouter } from "next/router";
import type { ErrorProps } from "next/error";
import Script from "next/script";
import { start } from "../lib/bugsnag";
import { AppProps } from "next/app";
import { DefaultSeo } from "next-seo";
import { SessionProvider } from "next-auth/react";
import "antd/dist/antd.css";
import "@styles/global.scss";
import { Provider } from "react-redux";
import store from "@redux/store";
import ChatProvider from "src/context/ChatProvider";
import SocketProvider from "src/context/SocketProvider";
import LocationProvider from "src/context/LocationProvider";
import { ErrorBoundary } from "@components";
import * as gtag from "../lib/gtag";

start();

const MyApp = ({ Component, pageProps: { session, ...pageProps }, err}: AppProps & { err: ErrorProps }) => {
  const router = useRouter()
  useEffect(() => {
    const handleRouteChange = (url: any) => {
      gtag.pageview(url);
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    router.events.on('hashChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
      router.events.off('hashChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <>
     {/* Global Site Tag (gtag.js) - Google Analytics */}
     <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
      />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gtag.GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
      <ErrorBoundary>
      <SessionProvider session={session}>
        <Provider store={store}>
          <LocationProvider>
            <SocketProvider>
              <ChatProvider>
                <Component {...pageProps} err={err} />
                {/* <DefaultSeo {...SEO}>
                  <Component {...pageProps} />
                </DefaultSeo> */}
              </ChatProvider>
            </SocketProvider>
          </LocationProvider>
        </Provider>
      </SessionProvider>
    </ErrorBoundary>
    </>
  )
}

export default MyApp


