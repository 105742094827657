import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSession } from "next-auth/react";
import {
  sendProductReview,
  fetchReviewListing,
  resetReviewData,
  fetchUserInfo
} from "@redux/actions";
import {
  Typography,
  Button as AntButton,
  Drawer,
  Rate,
  Input,
  message,
} from "antd";
import { breakpoint } from "../../helpers/homeUtils";
import { Button } from "@components";

const { Text } = Typography;
const { TextArea } = Input;

export const RateYourExperience = ({ id, title }: any) => {
  const dispatch = useDispatch();
  const { data: session, status }: any = useSession();
  const currentUser: any = session?.user || null;
  const { data, review } = useSelector((state: any) => state.manage);
  const userInfo = useSelector((state: any) => state.userProfile?.userInfo);

  const [rate, setRate] = useState(1);
  const [visible, setVisible] = useState(false);
  const [comment, setComment] = useState("");
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (review?.rating === rate) {
      setRate(1)
      setComment("")
      setLoading(false)
      setVisible(false)      
      dispatch(resetReviewData());
      dispatch(fetchReviewListing(id));
    }    
  }, [review])

  useEffect(() => {
    if (currentUser?.id) {
      dispatch(fetchUserInfo(currentUser.id));
    }
  }, [currentUser]);

  const onChange = (e: any) => {
    setComment(e.target.value.trim());
  };

  const isAccountVerified = () => {
    return (currentUser && currentUser.verified) || (userInfo && userInfo.verified);
  };

  const handleRateProduct = () => {
    if (!currentUser || (currentUser && !currentUser.id)) {
      message.warning("Login to view this feature");
      return;
    }
    if (!isAccountVerified()) {
      message.warning(
        "Please verify your account to use this feature. Check your inbox for email"
      );
      return;
    } else {
      setVisible(true);
    }
  };
  const handleSubmit = () => {
    const payload = {
      user: currentUser?.id,
      listing: id,
      description: comment,
      rating: `${rate}`,
      title: title
    }
    setLoading(true)
    dispatch(sendProductReview(payload))
  }
  return (
    <div className="mt-2 w-full">
      <Button block
        onClick={handleRateProduct}
      >
        <Text className="text-white uppercase">Rate Your Experience</Text>
      </Button>
      <Drawer
        title="Rate Your Experience"
        width={["xs", "sm"].includes(breakpoint()) ? "100%" : "35%"}
        onClose={() => setVisible(false)}
        visible={visible}
      >
        <div className="flex flex-col justify-center items-center">
          <Rate
            className="text-3xl"
            value={rate}
            onChange={(value) => setRate(value)}
          />
          <TextArea
            showCount
            maxLength={500}
            style={{ height: 120 }}
            onChange={onChange}
            placeholder="Type review here..."
            className="mt-3 w-full"
          />
          <AntButton
            className="mt-5 bg-red-500 text-white font-bold disabled:opacity-75"
            block
            disabled={!(rate && comment && comment.length > 2) || isLoading}
            onClick={handleSubmit}
          >
            SUBMIT
          </AntButton>
        </div>
      </Drawer>
    </div>
  );
};
