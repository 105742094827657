import React, { createContext, useContext, useState } from "react";

const LocationContext = createContext();

const LocationProvider = ({ children }) => {
  const [defaultAddress, setDefaultAddress] = useState("");
  const [formattedAddress, setFormattedAddress] = useState("");

  return (
    <LocationContext.Provider
      value={{
        defaultAddress,
        setDefaultAddress,
        formattedAddress,
        setFormattedAddress
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};

export const LocationState = () => {
  return useContext(LocationContext);
};

export default LocationProvider;