import clientApi from "../api/clientApi";
import ServerConfig from "../config/ServerConfig";

const getCategories = () => {
  const uri = `${ServerConfig.api.categories}`;
  return clientApi.get(uri);
}

const getReviewListing = (id) => {
  const uri = `${ServerConfig.api.reviewListing}/${id}`;
  return clientApi.get(uri);
}

export { getCategories, getReviewListing };