const isDev = process.env.NODE_ENV === 'development';

const AppConfig = {
  clientUrl: process.env.CLIENT_URL,
  clientUrlBrowser: process.env.NEXT_PUBLIC_CLIENT_URL,
  apiHost: !isDev ? process.env.API_HOST : process.env.API_HOST_DEV,
  apiHostBrowser: !isDev ? process.env.NEXT_PUBLIC_API_HOST : process.env.NEXT_PUBLIC_API_HOST_DEV,
  apiVersion: !isDev ? process.env.API_VERSION : process.env.API_VERSION_DEV,
  apiVersionBrowser: !isDev ? process.env.NEXT_PUBLIC_API_VERSION : process.env.NEXT_PUBLIC_API_VERSION_DEV,
  googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
  facebookAppId: process.env.FB_API_ID,
  secretOAuthProvider: process.env.OAUTH_SECRET_SALT,
  keyChatMessageAES: process.env.NEXT_PUBLIC_CHAT_MSG_AES_KEY,
  playStoreUrl: process.env.PLAY_STORE_URL,
  appStoreUrl: process.env.APP_STORE_URL,
  privacyPolicy: process.env.NEXT_PUBLIC_PRIVACY_POLICY,
  universalLinkUrl: process.env.UNIVERSAL_LINK_URL,
  supportEmail: process.env.SUPPORT_EMAIL,
  userDefaultAvatar: process.env.NEXT_PUBLIC_USER_DEFAULT_AVATAR,
  payment: {
    stripePublishingKey: !isDev ? process.env.NEXT_PUBLIC_STRIPE_PUBLISHING_KEY_LIVE : process.env.NEXT_PUBLIC_STRIPE_PUBLISHING_KEY
  },
  gaId: process.env.NEXT_PUBLIC_GA_ID,
  bugsnagAPIKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY,
  logo: process.env.NEXT_PUBLIC_LOGO
};

export default AppConfig;
