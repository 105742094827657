import io from "socket.io-client";
import ServerConfig from "src/config/ServerConfig";

let socket = null;
export const initConnection = (userId) => {
  console.log("[New socket connection]");
  socket = io(ServerConfig.api.hostBrowser, {
    transports: ["websocket", "polling"],
    query: { user: `${userId}` },
  });
}

export const disconnectSocket = () => {
  console.log("[Disconnected socket]");
  if (socket) socket.disconnect();
};

export const initSockets = ({ setValue }) => {
  socketEvents({ setValue });
};

export const socketEvents = ({ setValue }) => {
  // TODO: Check if socket is null. Then ask user to refresh page.
  socket.on("chat-message", (chatMessage) => {
    console.log("Websocket event received!");
    setValue(state => { return { ...state, chatMessage } });
    setTimeout(() =>{ // Otherwise chatMessage always contains the last message.
      setValue(state => { return { ...state, chatMessage: null } });  // TODO
    }, 1000);
  });

  socket.on("users-online", (usersOnline) => {
    console.log("Users online event received!");
    setValue(state => { return { ...state, usersOnline } });
  });

  socket.on("chat-status-seen", (chatStatusSeen) => {
    console.log("Websocket status seen event received!");
    setValue(state => { return { ...state, chatStatusSeen } });
  });
};

export const sendChatMessage = (message) => {
  // console.log(`[Chat Message]`, JSON.stringify(message));
  if (socket) socket.emit("chat-message", message);
};

export const sendChatStatusSeen = (message) => {
  if (socket) socket.emit("chat-status-seen", message);
};
