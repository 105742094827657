import React, { CSSProperties } from "react";
import Image from "next/image";
import { Space, Button, Row, Col, Divider } from "antd";
import {
  FacebookFilled,
  InstagramFilled,
  LinkedinFilled,
  AndroidFilled,
  AppleFilled,
  CopyrightCircleOutlined
} from "@ant-design/icons";
import Link from "next/link";

export const Footer: React.FC = () => {
  const iconStyle: CSSProperties = {
    fontSize: 22,
    color: "#fff",
  };
  return (
    <Row className="bg-red-500 py-2 md:px-8 justify-center" style={{ minHeight: 75 }}>
      <Col xs={24} md={8} className="flex flex-wrap items-center justify-center">
        <a
          href="https://app.loanrr.ca/privacy-policy.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button type="link" className="text-sm text-white font-bold px-1">
            Terms
          </Button>
        </a>
        <a
          href="https://app.loanrr.ca/faq.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button type="link" className="text-sm text-white font-bold px-1">
            FAQ
          </Button>
        </a>
        {/* TODO: Build a new form */}
        {/* <Link href="/userProfile/contactus">
          <Button type="link" className="text-sm text-white font-bold px-1">
            Contact Us
          </Button>
        </Link> */}
        <p className="text-sm text-white font-bold px-1">Email: support@loanrr.ca</p>
      </Col>
      <Col xs={24} md={8} className="flex items-center justify-center">
        <div className="flex">
        <h1 className="text-lg sm:text-sm md:pt-1 font-bold text-white">Follow Us:</h1>
          <a
            target="_blank"
            href="https://www.facebook.com/Loanrrapp"
            rel="noopener noreferrer"
          >
            <Button
              type="link"
              className="text-white hover:text-white active:text-white"
              icon={<FacebookFilled data-test="icon" className="text-2xl" />}
            />
          </a>
          <a
            target="_blank"
            href="https://www.instagram.com/loanrrapp"
            rel="noopener noreferrer"
          >
            <Button
              type="link"
              className="text-white hover:text-white active:text-white"
              icon={<InstagramFilled data-test="icon" className="text-2xl" />}
            />
          </a>
          <a
            target="_blank"
            href="https://www.linkedin.com/company/loanrrapp/"
            rel="noopener noreferrer"
          >
            <Button
              type="link"
              className="text-white hover:text-white active:text-white"
              icon={<LinkedinFilled data-test="icon" className="text-2xl" />}
            />
          </a>
        </div>
        <Divider type="vertical" style={{backgroundColor: "white"}} />
        <div className="flex pl-2">
          <h1 className="text-lg sm:text-sm md:pt-1 font-bold text-white">Our Apps:</h1>
          <a
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.loanrr.app"
            rel="noopener noreferrer"
          >
            <Button
              type="link"
              className="text-white hover:text-white active:text-white"
              icon={<AndroidFilled data-test="icon" className="text-2xl" />}
            />
          </a>
          <a
            target="_blank"
            href="https://apps.apple.com/us/app/loanrr/id1587579873"
            rel="noopener noreferrer"
          >
            <Button
              type="link"
              className="text-white hover:text-white active:text-white"
              icon={<AppleFilled data-test="icon" className="text-2xl" />}
            />
          </a>
        </div>
      </Col>
      <Col xs={24} md={8} className="flex items-center justify-center mt-1">
        <div className="flex">
          <CopyrightCircleOutlined className="text-sm px-1" style={{color: "white"}} title="Loanrr Classifieds"/>
          <p className="text-sm text-white font-bold px-1">Loanrr Classifieds Inc, 2022</p>
        </div>
      </Col>
    </Row>
  );
};
