export const ErrorCode = {
  failedApiCall: 'failedApiCall',
  noResponseFromApi: 'noResponseFromApi',
  passwordInUse: 'passwordInUse',
  badEmailFormat: 'badEmailFormat',
  emailInUse: 'emailInUse',
  invalidPassword: 'invalidPassword',
  noUser: 'noUser',
  rateLimited: 'rateLimited',
  serverError: 'serverError',
  photoUploadFailed: 'photoUploadFailed',
  fbAuthCancelled: 'Facebook authentication cancelled',
  appleAuthCancelled: 'Apple authentication cancelled',
  fbAuthFailed: 'Facebook authentication Failed',
  googleAuthFailed: 'Google authentication Failed',
  appleAuthFailed: 'Apple authentication Failed',
  smsNotSent: 'smsNotSent',
  invalidSMSCode: 'invalidSMSCode',
  noUserDataFound: 'noUserDataFound',
  noUserTokenDataFound: 'noUserTokenDataFound',
  failedToGetUserData: 'failedToGetUserData',
  failedToGetUserTokenData: 'failedToGetUserTokenData',
  locationServiceDisabled: 'Make sure location service is enabled',
  locationPermissionDenied: 'Permission to access location was denied',
  NEARBY_ADS_NO_RESPONSE: 'NEARBY_ADS_NO_RESPONSE',
  NEARBY_ADS_SUCCESS_RESPONSE: 'NEARBY_ADS_SUCCESS_RESPONSE',
  GENERIC_ERROR_MESSAGE: 'Server connection lost',
  IMAGE_UPLOAD_FAILED: 'Failed to upload Image',
  SENSITIVE_IMAGE_FOUND: 'Image seems to be sensitive. Please avoid uploading such images. Contact support for any discrepancies',
  USER_DENIED_GEOLOCATION: 'Location permission denied. Please allow Location access for better experience.',
  LOCATION_QUERY_LIMIT_EXCEEDED: 'Too many Location query request',
  FAILED_GEO_LOCATION: 'Failed to get Geo Location',
  // SignUp Screen
  EMAIL_ALREADY_REGISTERED_MSG: 'Email already registered',
  EMAIL_ALREADY_REGISTERED_DESC:
    'We found the Email already registered with us. Use Login screen to login to your account',
  SIGNUP_FAILED_MSG: 'Failed to SignUp',
  VERIFY_ACCOUNT_MSG: 'Please verify your account',
  VERIFY_ACCOUNT_DESC: 'We have sent a verification link to your Email',
  // Reset Password
  VERIFY_EMAIL_FAILED_MSG: 'Failed to verify Email',
  VERIFY_TOKEN_FAILED_MSG: 'Failed to verify Token',
  VERIFY_TOKEN_FAILED_DESC: 'Please check you have entered the correct Token',
  RESET_PASSWORD_FAILED_MSG: 'Failed to reset password',
  RESET_PASSWORD_FAILED_DESC: 'Failed to reset password',
  RESET_PASSWORD_SUCCESS_MSG: 'Successfully reset password',
  RESET_PASSWORD_SUCCESS_DESC: 'Password reset. Login with the new credentials',
  // Delete Account
  DELETE_ACCOUNT_MSG: 'Delete Account',
  DELETE_ACCOUNT_WARNING1:
    'All your data will be wiped out from Loanrr. Are you sure?',
  DELETE_ACCOUNT_WARNING2:
    "This action can't be reverted. Are you sure want to Delete Account?",
  DELETE_ACCOUNT_SUCCESS_MSG: 'Account Deleted Successfully',
  DELETE_ACCOUNT_FAILED_MSG: 'Failed to Delete Account',
  DELETE_ACCOUNT_FAILED_DESC: 'Contact Support for assistance',

  //POST calls fail/success
  // Auth service
  EMAIL_SIGNUP_SUCCESS: 'Email sign up success',
  EMAIL_SIGNUP_FAILED: 'Failed to sign up with Email',
  OAUTH_SIGNUP_SUCCESS: 'OAuth sign up success',
  OAUTH_SIGNUP_FAILED: 'Failed to sign up with OAuth',
  GENERATE_OTP_SUCCESS: 'OTP generated',
  GENERATE_OTP_FAILED: 'Failed to generate OTP',
  VERIFY_TOKEN_SUCCESS: 'Token verified',
  VERIFY_TOKEN_FAILED: 'Failed to verify token',
  RESET_PASSWORD_SUCCESS: 'Reset password success',
  RESET_PASSWORD_FAILED: 'Failed to reset password',
  // chat service
  // listing service
  POST_USER_BLOCK_SUCCESS: 'User blocked',
  POST_USER_BLOCK_FAILED: 'Failed to block user',
  POST_USER_UNBLOCK_SUCCESS: 'User unblocked',
  POST_USER_UNBLOCK_FAILED: 'Failed to unblock user',
  POST_SEND_REPORT_USER_SUCCESS: 'Issue reported',
  POST_SEND_REPORT_USER_FAILED: 'Failed to report issue. Contact Support on Email',
  // Listing Details
  POST_MANAGE_DATA_SUCCESS: 'Data saved',
  POST_MANAGE_DATA_FAILED: 'Failed to save data',
  UPDATE_LISTING_SUCCESS: 'Successfully Ad updated',
  UPDATE_LISTING_FAILED: 'Failed to update Ad',
  POST_PRODUCT_REVIEW_SUCCESS: 'Review added',
  POST_PRODUCT_REVIEW_FAILED: 'Failed to add review',
  POST_PRODUCT_REPORT_SUCCESS: 'Issue reported',
  POST_PRODUCT_REPORT_FAILED: 'Failed to report issue',
  LISTING_EXPIRES_SOON_BANNER_TEXT: 'Listing expires soon',
  LISTING_EXPIRED_BANNER_TEXT: 'Listing expired',
  CONVERT_FEATURED_AD_FAILED: 'Failed to convert to Featured Ad. Contact support for more information',
  RENEW_LISTING_FAILED: 'Failed to renew Ad. Contact support for more information',

  // new ad service
  POST_UPLOAD_FILE_API_SUCCESS: 'File uploaded',
  POST_UPLOAD_FILE_API_FAILED: 'Failed to upload file',
  UPDATE_PROFILE_PIC_SUCCESS: 'Profile picture updated',
  UPDATE_PROFILE_PIC_FAILED: 'Failed to update profile picture',
  NO_FREE_ADS_NO_FEATURED_ADS: 'Sorry, Maximum allowed Ads exceeded/expired.\nPlease update your subscription or contact support for additional information.',
  NO_FREE_ADS_BUT_FEATURED_ADS: 'Sorry, No Free Ads available but you can post Featured Ads.',
  NO_FEATURED_ADS_BUT_FREE_ADS: 'Sorry, No Featured available but you can post Free Ads.',
  GET_FEATURED_ADS_BANNER: 'Post Featured Ads and get noticed with "Featured" tag on the Ad and reach more borrowers.',

  // user profile
  PATCH_USER_LISTINGS_SUCCESS: 'Listing updated',
  PATCH_USER_LISTINGS_FAILED: 'Failed to update listing',
  PATCH_USER_INFO_SUCCESS: 'Information updated',
  PATCH_USER_INFO_FAILED: 'Failed to update information',
  POST_USER_SETTINGS_SUCCESS: 'Settings saved',
  POST_USER_SETTINGS_FAILED: 'Failed to save settings',
  UPDATE_USER_NOTIFICATIONS_SUCCESS: 'Notifications updated',
  UPDATE_USER_NOTIFICATIONS_FAILED: 'Failed to update notifications',
  DELETE_USER_ACCOUNT_SUCCESS: 'User account deleted',
  DELETE_USER_ACCOUNT_FAILED: 'Failed to delete user account. Contact support on Email',
  POST_APP_REPORT_SUCCESS: 'Issue reported',
  POST_APP_REPORT_FAILED: 'Failed to report issue. Please try again or contact support on Email.',

  // others
  VERIFICATION_ALERT: 'Unverified. Check your inbox for verification link',
  REQUIRED_ADDRESS: 'Address field is required',
  OFFENSIVE_WORDS: 'Offensive/sensitive words are not allowed. Please check Title and Description.',
  AD_POSTED: 'Congrats! Your Ad posted successfully',
  AD_UPDATED: 'Congrats! Your Ad updated successfully',
}
