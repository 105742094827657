import React from "react";
import Image from "next/image";
import Link from "next/link";
import { Row, Col, Divider, Typography, Tooltip } from "antd";
import { CalendarOutlined, EnvironmentOutlined } from "@ant-design/icons";
import { calculateAgoTimeFull } from "../../helpers/timeFormat";

const { Title, Text } = Typography;

export const Card: React.FC<{
  images: any;
  title: String;
  postedTime: any;
  amount: any;
  amountPer: any;
  address: any;
  id: any,
  isFeatured: any
}> = (props) => {
  const img =
    props?.images?.[0] || process.env.NEXT_PUBLIC_LOGO;
  const {
    title = "Title",
    postedTime = new Date(),
    amount = null,
    amountPer = null,
    address = null,
    id = null,
    isFeatured = false
  } = props;

  const imageLoader = ({ src, width, quality }: any) => {
    return `${src}?tr:w-${width}&tr:q-${quality || 75}`
  };

  return (
    <Link href={`/listing/${id}`}>
      <Row className="border rounded bg-white hover:shadow-lg mx-1 card">
        <Col xs={24} className="relative">
          <Image
            loader={imageLoader}
            data-test="icon"
            src={img}
            alt="Loanrr Listing"
            layout="responsive"
            width="100"
            height="65"
            className="border-top-left-right card-img"
          />
          {isFeatured && <div className="featured-tag shadow-lg border">Featured</div>}
        </Col>
        <Col xs={24} className="p-2">
          <Tooltip title={title}>
            <Text className="text-sm font-bold truncate">{title}</Text>
          </Tooltip>
          <div>
            <Text type="secondary" className="text-xs">
              <CalendarOutlined /> {calculateAgoTimeFull(postedTime)}
            </Text>
          </div>
          {amount && (
            <Text className="text-sm font-bold">
              {`$` + amount + "/" + amountPer}
            </Text>
          )}
        </Col>
        <Divider className="p-0 m-0" />
        <Col xs={24} className="p-2">
          <Tooltip title={address}>
            <Text type="secondary" className="text-xs truncate">
              <EnvironmentOutlined /> {address}
            </Text>
          </Tooltip>
        </Col>
      </Row>
    </Link>
  );
};
