import React, { useEffect } from "react";
import { message as antMessage } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { resetStatusMessage } from "@redux/actions";

export const StatusMessages = () => {
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = antMessage.useMessage();
  const { type, message, duration } = useSelector((state: any) => state.statusMessages);

  useEffect(() => {
    switch (type) {
      case "success":
        antMessage.success(message, duration);
        break;
      case "info":
        antMessage.info(message, duration);
        break;
      case "warning":
        antMessage.warning(message, duration);
        break;
      case "error":
        antMessage.error(message, duration);
        break;
      default:
        break;
    }
    dispatch(resetStatusMessage());
  }, [type]);
  return null;
};
