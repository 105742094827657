import { LeftOutlined, RightOutlined } from "@ant-design/icons";

const SlickButtonFix = ({currentSlide, slideCount, children, ...props}) => (
  <span {...props}>{children}</span>
);

export const carouselSettings = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  arrows: true,
  prevArrow: (
    <SlickButtonFix>
      <LeftOutlined />
    </SlickButtonFix>
  ),
  nextArrow: (
    <SlickButtonFix>
      <RightOutlined />
    </SlickButtonFix>
  ),
  adaptiveHeight: false,
  autoplay: true,
  responsive: [
    {
      breakpoint: 480,
      settings: {
        arrows: false,
      },
    },
  ],
};

export const carouselAdsSettings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 3,
  initialSlide: 0,
  arrows: true,
  prevArrow: (
    <SlickButtonFix>
      <LeftOutlined />
    </SlickButtonFix>
  ),
  nextArrow: (
    <SlickButtonFix>
      <RightOutlined />
    </SlickButtonFix>
  ),
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 3,
        infinite: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 0,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

export const carouselCatsSettings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 8,
  slidesToScroll: 6,
  initialSlide: 0,
  arrows: true,
  prevArrow: (
    <SlickButtonFix>
      <LeftOutlined />
    </SlickButtonFix>
  ),
  nextArrow: (
    <SlickButtonFix>
      <RightOutlined />
    </SlickButtonFix>
  ),
  responsive: [
    {
      breakpoint: 1108,
      settings: {
        slidesToShow: 8,
        slidesToScroll: 6,
        infinite: false,
      },
    },
    {
      breakpoint: 1048,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 4,
        infinite: false,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 3,
        initialSlide: 0,
      },
    },
    {
      breakpoint: 560,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 3,
      },
    },
  ],
};

export const carouselReviewsSettings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide: 0,
  arrows: true,
  prevArrow: (
    <SlickButtonFix>
      <LeftOutlined />
    </SlickButtonFix>
  ),
  nextArrow: (
    <SlickButtonFix>
      <RightOutlined />
    </SlickButtonFix>
  ),
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

export const breakpoint = () => {
  const breakpoints = {
    "(min-width: 1200px)": "xl",
    "(min-width: 992px) and (max-width: 1199.98px)": "lg",
    "(min-width: 768px) and (max-width: 991.98px)": "md",
    "(min-width: 576px) and (max-width: 767.98px)": "sm",
    "(max-width: 575.98px)": "xs",
  };
  
  for (let media in breakpoints) {
    if (typeof window !== "undefined" && window.matchMedia(media).matches) {
      return breakpoints[media];
    }
  }

  return null;
};
