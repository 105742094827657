import React, { useState } from "react";
import { Button } from "@components";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { showModalPopup as toggleModalPopup } from "@redux/actions";
import TipsforAds from "./TipsforAds";
import TipsforChat from "./TipsforChat";

export const ModalPopup: React.FC = () => {
  const dispatch = useDispatch();
  const { showModalPopup = null }: any = useSelector(
    (state: any) => state.userProfile
  );
  const handleOk = () => {
    dispatch(toggleModalPopup(null));
  };
  if (!showModalPopup?.title) {
    return null;
  }
  return (
    <Modal
      title={showModalPopup?.title}
      visible={showModalPopup?.title}
      onOk={handleOk}
      closable={false}
      footer={[<Button onClick={handleOk}>Understand</Button>]}
    >
      <>{showModalPopup?.type === "NEW_ADD" && <TipsforAds />}</>
      <>{showModalPopup?.type === "CHAT" && <TipsforChat />}</>
    </Modal>
  );
};
