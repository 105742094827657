import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { getAppSettings} from "../../../services/appSettings.service"

const defaultAppSettings = {
  listing: {
      maxTitleLength: 100,
      maxDescriptionLength: 500,
      maxListingImages: 6,
      listingExpiryDays: 30,
      maxListingsPerMonth: 30,
      maxFavoriteListings: 15,
      paymentModes: [
          "Cash",
          "Contact less",
          "Any"
      ],
      amountPer: [
          "Hour",
          "Day",
          "Week",
          "Month",
          "Year",
          "Life"
      ],
      deliveryMethods: [
          "Can Deliver For Free",
          "Can Deliver For Additional Charge",
          "Pickup Only",
          "Curbside Pickup"
      ],
      expiryReminderDays: 3
  },
  type: "Web",
  allowNotifications: true,
  nearByDistanceInKM: 100,
  showSubscriptions: false,
  other: { loginAttempts: 3},
  showGoogleAds: false
};

interface IAppSettings {
  web: any;
}

const initialState: IAppSettings = {
  web: defaultAppSettings,
};

export const fetchAppSettings = createAsyncThunk(
  "appSettings/web",
  async () => {
    const res = await getAppSettings();
    return res?.data || defaultAppSettings;
  }
);

const appSettingsSlice = createSlice({
    name: "appSettings",
    initialState,
    extraReducers: {
        [fetchAppSettings.fulfilled.type]: (
          state: any,
          { payload }: PayloadAction<any>
        ) => {
          return {...state, web: {...payload} };
        }
    },
    reducers: {}
});

export const {} = appSettingsSlice.actions;

export default appSettingsSlice.reducer;
