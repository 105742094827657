import React from "react";
import { Button as BaseButton } from "antd";
import { ButtonProps } from "antd/lib/button";

export type IButton = ButtonProps;

export const Button: React.FC<IButton> = ({ ...rest }) => {
  return (
        <BaseButton 
            className="text-white font-bold text-md bg-red-600 hover:bg-red-500 border-red-600 hover:border-red-500"
            {...rest}

        />
    );
};
