import React, { useEffect } from "react";

export const Adsense = ({ type = null }: any) => {
  const loadAds = () => {
    try {
      if (typeof window !== "undefined") {
        (window.adsbygoogle = window.adsbygoogle || []).push({ google_ad_client: process.env.NEXT_PUBLIC_GOOGLE_ADSENSE });
      }
    } catch (error: any) {
      console.log("[Adsense Error]", error.message);
    }
  };

  useEffect(() => {
    loadAds();
  }, []);

  if (type === "DISPLAY-SQUARE") {
    return (
      <>
        <ins className="adsbygoogle"
          style={{ display: "block" }}
          data-ad-client="ca-pub-1987082751236842"
          data-ad-slot="6422329777"
          data-ad-format="auto"
          data-full-width-responsive="true"></ins>
      </>
    );
  }

  if (type === "DISPLAY-VERTICAL") { // Display Square
    return (
      <>
        <ins
          className='adsbygoogle'
          style={{ display: "block" }}
          data-ad-client='ca-pub-1987082751236842'
          data-ad-slot='4579177446'
          data-ad-format='auto'
          data-full-width-responsive='true'
        ></ins>
      </>
    );
  }

  if (type === "MULTI-HORIZONTAL") {
    return (
      <>
        <ins
          className='adsbygoogle'
          style={{ display: "block", height: "250px" }}
          data-ad-format='autorelaxed'
          data-ad-client='ca-pub-1987082751236842'
          data-ad-slot='3234306185'
        />
      </>
    );
  }

  if (type === "MULTI-VERTICAL") {
    return (
      <>
        <ins
          className='adsbygoogle'
          style={{ display: "block", height: "250px" }}
          data-ad-format='autorelaxed'
          data-ad-client='ca-pub-1987082751236842'
          data-ad-slot='4894872216'
        />
      </>
    );
  }

  if (type === "IN-ARTICLE") {
    return (
      <>
        <ins className="adsbygoogle"
          style={{ display: "block", textAlign: "center" }}
          data-ad-layout="in-article"
          data-ad-format="fluid"
          data-ad-client="ca-pub-1987082751236842"
          data-ad-slot="3706411378"></ins>
      </>
    );
  }

  if (type === "IN-FEED") {
    return (
      <>
        <ins className="adsbygoogle"
          style={{ display: "block"}}
          data-ad-format="fluid"
          data-ad-layout-key="-7s+dk+2n+9-38"
          data-ad-client="ca-pub-1987082751236842"
          data-ad-slot="3306293414"></ins>
      </>
    );
  }

  return (
    <>
      <ins className="adsbygoogle"
          style={{ display: "block", textAlign: "center" }}
          data-ad-layout="in-article"
          data-ad-format="fluid"
          data-ad-client="ca-pub-1987082751236842"
          data-ad-slot="3706411378"></ins>
    </>
  );
};
