import { Carousel, Select, Row, Col, Typography, Spin, Button } from "antd";
import Image from "next/image";
import Link from "next/link";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Adsense} from "@components";
import {
  carouselSettings,
  carouselAdsSettings,
  carouselCatsSettings,
} from "../../helpers/homeUtils";
import {
  getNearbyListings,
  fetchCategories,
  fetchFeaturedListing,
  fetchCarousel,
  resetNearByListing,
} from "@redux/actions";
import { LocationState } from "../../context/LocationProvider";

const { Title, Text } = Typography;

export const Main: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const {
    nearByListing = null,
    featuredListing = null,
    carousel = null,
    savedSearch = null,
  } = useSelector((state: any) => state.listings);
  const { categories } = useSelector((state: any) => state.search);
  const appSettings: any = useSelector(
    (state: any) => state.appSettings?.web || null
  );

  const [pageNearby, setPageNearby] = useState(1);
  const { defaultAddress, formattedAddress } = LocationState();
  const pgNum = useRef<any>(null);

  const isDev = process.env.NODE_ENV === "development";

  const getSavedLocation = () => {
    let coords = [43.3255196, -79.7990319];
    if (typeof window !== "undefined" && formattedAddress) {
      const geo = (JSON.parse(formattedAddress) || "{}")?.geo || {
        lat: coords[0],
        lng: coords[1],
      };
      coords = [geo.lat, geo.lng];
    }
    return coords;
  };

  useEffect(() => {
    dispatch(resetNearByListing());
    const coordinates = getSavedLocation();
    // let coordinates = [43.3255196, -79.7990319];  // TODO: Only for Google Adsense
    const location = { coordinates };
    const payload = {
      location: location,
      radius: +(savedSearch?.distance || 100),
      page: 1,
    };
    dispatch(fetchCategories());
    dispatch(fetchFeaturedListing({ ...payload }));
    dispatch(fetchCarousel());
    return () => {
      dispatch(resetNearByListing());
    };
  }, [formattedAddress]);

  useEffect(() => {
    dispatch(resetNearByListing());
    const coordinates = getSavedLocation();
    // let coordinates = [43.3255196, -79.7990319];  // TODO: Only for Google Adsense
    const location = { coordinates };
    const payload = {
      location: location,
      radius: +(savedSearch?.distance || 100),
      page: 1,
    };
    dispatch(fetchFeaturedListing({ ...payload }));
    return () => {
      dispatch(resetNearByListing());
    };
  }, [savedSearch?.distance, formattedAddress]);

  useEffect(() => {
    if (pageNearby !== pgNum.current) {
      const coordinates = getSavedLocation();
      // let coordinates = [43.3255196, -79.7990319];  // TODO: Only for Google Adsense
      const location = { coordinates };
      const payload = {
        location: location,
        radius: +(savedSearch?.distance || 100),
        page: pageNearby,
        __ref: null,
      };
      pgNum.current = pageNearby;
      if (nearByListing.error !== "EMPTY") {
        dispatch(getNearbyListings({ ...payload }));
      }
    }
  }, [pageNearby]);

  useEffect(() => {
    dispatch(resetNearByListing());
    const coordinates = getSavedLocation();
    // let coordinates = [43.3255196, -79.7990319];  // TODO: Only for Google Adsense
    const location = { coordinates };
    const payload = {
      location: location,
      radius: +(savedSearch?.distance || 100),
      page: 1,
      __ref: null,
    };
    pgNum.current = 1;
    dispatch(getNearbyListings({ ...payload }));
  }, [pageNearby, savedSearch?.distance, formattedAddress]);

  function fetchMoreListItems() {
    setPageNearby((prev) => prev + 1);
  }

  const imageLoader = ({ src, width, quality }: any) => {
    return `${src}?tr:w-${width}&tr:q-${quality || 75}`;
  };

  // To reload session on client. https://github.com/nextauthjs/next-auth/issues/596#issuecomment-943453568
  const reloadSession = () => {
    const event = new Event("visibilitychange");
    document && document.dispatchEvent(event);
  };

  return (
    <div className='px-2 sm:px-0' id='home-page'>
      {!!carousel?.length ? (
        <Carousel {...carouselSettings}>
          {carousel.map((item: any, index: any) => (
            <div key={index}>
              <Link href={`/listing?q=${item.category}`}>
                <div
                  style={{ width: "100%", cursor: "pointer" }}
                  className='relative'
                >
                  <Image
                    priority
                    loader={imageLoader}
                    src={item.imageUrl}
                    alt={item.title || "Carousel"}
                    layout='responsive'
                    width='1600'
                    height='400'
                  />
                </div>
              </Link>
            </div>
          ))}
        </Carousel>
      ) : (
        <div className='text-center m-5'>
          <Spin />
        </div>
      )}
      <Title className='mb-2 mt-3' level={5}>
        Categories
      </Title>
      <div className=''>
        <Carousel {...carouselCatsSettings} className='home-carousel'>
          {!!Object.values(categories || {}).length &&
            [...Object.values(categories)].map(
              ({ name, imageUrl, id }: any, index: number) => (
                <Link href={`/listing?q=${id}`} key={index}>
                  <div className='border border-gray-200 hover:shadow-lg radius rounded p-1 card mx-1 shadow'>
                    <div className='m-auto text-center'>
                      <Image
                        loader={imageLoader}
                        data-test='icon'
                        src={imageUrl}
                        alt='Category'
                        width='80'
                        height='80'
                      />
                    </div>
                    <div className='m-auto text-center'>
                      <Text
                        className='text-xs sm:text-sm block text-center truncate'
                        style={{ textShadow: "1px 1px 5px #ccc" }}
                      >
                        {name.toLowerCase() === "home" ? "All" : name}
                      </Text>
                    </div>
                  </div>
                </Link>
              )
            )}
        </Carousel>
      </div>
      {!!featuredListing?.length && (
        <Title className='mb-2 mt-3' level={5}>
          Featured Ads
        </Title>
      )}
      {!featuredListing && (
        <div className='text-center m-5'>
          <Spin />
        </div>
      )}
      <Carousel {...carouselAdsSettings} className='home-carousel'>
        {featuredListing?.map((data: any, index: any) => (
          <Card {...data} key={index} />
        ))}
      </Carousel>
      {(appSettings?.showGoogleAds || isDev) && (
        <Adsense type='IN-ARTICLE' />
      )}
      {!!nearByListing?.data?.length && (
        <>
          <Title className='mb-2' level={5}>
            Recent Ads
          </Title>
          <Row gutter={[0, 15]} className='m-0 mb-4' id='recent-ads'>
            {nearByListing?.data?.map((data: any, index: Number) => (
              <Col xs={12} sm={8} md={6} lg={4} key={index.toString()}>
                <Card {...data} />
              </Col>
            ))}
            <Col
              xs={24}
              className='mt-2 block justify-center items-center flex'
            >
              {!nearByListing?.loading &&
              pageNearby < nearByListing?.totalPages ? (
                <Button
                  onClick={() => fetchMoreListItems()}
                  className='bg-white border-slate-200 text-red-500 font-bold'
                >
                  Load More
                </Button>
              ) : null}
            </Col>
          </Row>
        </>
      )}
      {nearByListing?.loading && (
        <div className='text-center m-5'>
          <Spin />
        </div>
      )}
    </div>
  );
};

// export default Main;
