import clientApi from "../api/clientApi";
import ServerConfig from "../config/ServerConfig";

const getManageData = (id) => {
  const uri = `${ServerConfig.api.manageListings}/${id}`;
  return clientApi.get(uri);
}

const postManageData = (payload) => {
  const uri = `${ServerConfig.api.listings}`;
  return clientApi.post(uri, payload);
}

const updateListing = (id, payload) => {
  const uri = `${ServerConfig.api.manageListings}/${id}`;
  return clientApi.patch(uri, payload);
}

const renewListing = (id) => {
  const uri = `${ServerConfig.api.renewListing}/${id}`;
  return clientApi.patch(uri);
}

const postProductReview = (payload) => {
  const uri = `${ServerConfig.api.reviews}`;
  return clientApi.post(uri, payload);
}

const postProductReport = (payload) => {
  const uri = `${ServerConfig.api.reportListing}`;
  return clientApi.post(uri, payload);
}

export { getManageData, postManageData, updateListing, renewListing, postProductReview, postProductReport };