import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPublicUserProfile,
  toggleBlockDrawer as toggleBlockDrawerAction,
} from "@redux/actions";
import { Drawer } from "antd";
import { breakpoint } from "../../helpers/homeUtils";
import UserInfo from "./UserInfo";
import ReportUser from "./ReportUser";

export const ToggleUserBlock = () => {
  const dispatch = useDispatch();
  const { toggleBlockDrawer = null }: any = useSelector(
    (state: any) => state.userProfile
  );

  const [visible, setVisible] = useState(false);
  const [state, setState] = useState('U');

  useEffect(() => {
    if (toggleBlockDrawer) {
      setVisible(true);
      dispatch(fetchPublicUserProfile(toggleBlockDrawer));
    }
  }, [toggleBlockDrawer]);

  return (
    <>
      <Drawer
        title={state === 'U' ? "User Details" : "Report User"}
        width={["xs", "sm"].includes(breakpoint()) ? "100%" : "35%"}
        onClose={() => {
          setVisible(false);
          setState('U')
          dispatch(toggleBlockDrawerAction(null));
        }}
        visible={visible}
      >
        {state === 'U' && <UserInfo changeState={() => setState('R')} />}
        {state === 'R' && <ReportUser changeState={() => setState('U')} />}
      </Drawer>
    </>
  );
};
