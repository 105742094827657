import React from "react";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
  WhatsappShareButton,
  WhatsappIcon
} from 'next-share';
import { Menu } from 'antd';

type Props = { title: string; url: string, description: string };

export const SocialMediaButtons: React.FC<Props> = ({ title, url, description }) => {
  return (
    <Menu mode="horizontal"
      style={{ minWidth: "100px" }}
      className="pt-2 border-solid border-2 border-neutral-100"
    >
      <Menu.Item>
        <FacebookShareButton
          url={url}
          quote={title}
          hashtag={"loanrr"}
        >
          <FacebookIcon size={30} round />
        </FacebookShareButton>
      </Menu.Item>
      <Menu.Item>
        <TwitterShareButton
          url={url}
          title={title}
        >
          <TwitterIcon size={30} round />
        </TwitterShareButton>
      </Menu.Item>
      {/* <Menu.Item>
        <EmailShareButton
          url={url}
          subject={title}
          body={description}
        >
          <EmailIcon size={30} round />
          Email
        </EmailShareButton>
      </Menu.Item> */}
      <Menu.Item>
        <WhatsappShareButton
          url={url}
          title={title}
          separator=":: "
        >
          <WhatsappIcon size={30} round />
        </WhatsappShareButton>
      </Menu.Item>
    </Menu>
  );
};
