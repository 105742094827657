import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  getManageData,
  postProductReview,
  postProductReport,
} from "../../../services/manage.service";
import { getReviewListing } from "../../../services/search.service";
import { setStatusMessage, resetStatusMessage } from "../../actions";
import { ErrorCode } from "../../../utils/ErrorCode";

export const fetchManageData = createAsyncThunk(
  "manage/getData",
  async (id: any) => {
    const res = await getManageData(id);
    return res.data;
  }
);

export const fetchReviewListing = createAsyncThunk(
  "manage/reviewListing",
  async (id: any) => {
    const res = await getReviewListing(id);
    return res.data;
  }
);

export const sendProductReview = createAsyncThunk(
  "manage/sendProductReview",
  async (payload: any, thunkAPI: any) => {
    try {
      const res = await postProductReview(payload);
      thunkAPI.dispatch(
        setStatusMessage({
          type: "success",
          message: ErrorCode.POST_PRODUCT_REVIEW_SUCCESS,
        })
      );
      return res.data;
    } catch(error: any) {
      thunkAPI.dispatch(
        setStatusMessage({
          type: "error",
          message:
            error?.response?.data?.message || ErrorCode.POST_PRODUCT_REVIEW_FAILED,
        })
      );
    }
  }
);

export const sendProductReport = createAsyncThunk(
  "manage/sendProductReport",
  async (payload: any, thunkAPI: any) => {
    try {
      const res = await postProductReport(payload);    
      thunkAPI.dispatch(
        setStatusMessage({
          type: "success",
          message: ErrorCode.POST_PRODUCT_REPORT_SUCCESS,
        })
      );
      return res.data;
    } catch(error: any) {
      thunkAPI.dispatch(
        setStatusMessage({
          type: "error",
          message:
            error?.response?.data?.message || ErrorCode.POST_PRODUCT_REPORT_FAILED,
        })
      );
    }
  }
);

const initialState: any = {
  data: {
    payload: null,
    loading: true
  },
  review: null,
  reviewListing: null,
  report: null,
};

const manageSlice = createSlice({
  name: "manage",
  initialState: initialState,
  extraReducers: {
    [fetchManageData.pending.type]: (
      state: any, action: any
    ) => {
      return { ...state, data: { payload: null, loading: true }, review: null };
    },
    [fetchManageData.fulfilled.type]: (
      state: any,
      { payload }: PayloadAction<any>
    ) => {
      return { ...state, data: { payload, loading: false }, review: null };
    },
    [sendProductReview.fulfilled.type]: (
      state: any,
      { payload }: PayloadAction<any>
    ) => {
      return {
        ...state,
        review: { ...payload },
      };
    },
    [sendProductReport.rejected.type]: (
      state: any,
    ) => {
      return {
        ...state,
        report: null,
        review: null,
      };
    },
    [sendProductReport.fulfilled.type]: (
      state: any,
      { payload }: PayloadAction<any>
    ) => {
      return {
        ...state,
        report: { ...payload },
        review: null,
      };
    },
    [fetchReviewListing.fulfilled.type]: (
      state: any,
      { payload }: PayloadAction<any>
    ) => {
      return { ...state, reviewListing: [ ...payload ], review: null };
    },
  },
  reducers: {
    resetReviewData(state: any) {
      return {
        ...state,
        review: null,
        report: null,
      };
    },
    resetReportData(state: any) {
      return {
        ...state,
        review: null,
        report: null,
      };
    },
  },
});

const { reducer, actions } = manageSlice;

export const { resetReviewData, resetReportData } = actions

export default reducer;
