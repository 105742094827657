import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  getChats,
  getListingChats,
  getUserChats,
  getBlockStatusByPostedUser
} from "../../../services/chat.service";

interface Ichat {
  chatsList: any,
  listing: any,
  newChatListing: any
}

const initialState: Ichat = {
  chatsList: [],
  listing: [],
  newChatListing: null
};

export const fetchChats = createAsyncThunk("chats/chats", async () => {
  const res = await getChats();
  return res?.data?.results || [];
});

export const fetchUserChats = createAsyncThunk(
  "chats/userChats",
  async (userId: any) => {
    const res = await getUserChats(userId);
    return res?.data || [];
  }
);

export const fetchListingChats = createAsyncThunk(
  "chats/listingChats",
  async (payload: { listingId: String; from: String; to: String }) => {
    const res = await getListingChats({ ...payload });
    return res?.data || [];
  }
);

export const fetchBlockStatusByPostedUser = createAsyncThunk(
  "chats/fetchBlockStatusByPostedUser",
  async ({ currentUserId, postedUserId }: any) => {
    const res = await getBlockStatusByPostedUser(currentUserId, postedUserId);
    return res.data;
  }
);

const chatSlice = createSlice({
  name: "chats",
  initialState,
  extraReducers: {
    [fetchChats.fulfilled.type]: (
      state: any,
      { payload }: PayloadAction<any>
    ) => {
      return [...payload];
    },
    [fetchUserChats.fulfilled.type]: (
      state: any,
      { payload }: PayloadAction<any>
    ) => {
      return { ...state, chatsList: [...payload] };
    },
    [fetchListingChats.fulfilled.type]: (
      state: any,
      { payload }: PayloadAction<any>
    ) => {
      return { ...state, listing: [...(payload?.messages || [])] };
    },
    [fetchBlockStatusByPostedUser.fulfilled.type]: (
      state: any,
      { payload }: PayloadAction<any>
    ) => {
      return { ...state, blockedByPostedUser: payload.blocked };
    },
  },
  reducers: {
    newChatListing(state, action) {
      state.newChatListing = {...action?.payload}
    }
  },
});



export const { newChatListing } = chatSlice.actions;

export default chatSlice.reducer;
