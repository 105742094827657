import { combineReducers } from "redux";

import counter from "@redux/slices/counter";
import listings from "@redux/slices/listings";
import search from "@redux/slices/search";
import manage from "@redux/slices/manage";
import chat from "@redux/slices/chat";
import userProfile from "@redux/slices/userProfile";
import appSettings from "@redux/slices/appSettings";
import statusMessages from "@redux/slices/statusMessages";
import notifications from "@redux/slices/notifications";
import auth from "@redux/slices/auth"

const rootReducer = combineReducers({ 
  counter,
  listings,
  search,
  manage,
  chat,
  userProfile,
  appSettings,
  statusMessages,
  notifications,
  auth
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;