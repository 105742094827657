import { io } from "socket.io-client";
import clientApi from "../api/clientApi";
import ServerConfig from "../config/ServerConfig";

const getChats = () => {
  const uri = `${ServerConfig.api.chats}`;
  return clientApi.get(uri);
};

const getUserChats = (userId) => {
  const uri = `${ServerConfig.api.userChatList}/${userId}`;
  return clientApi.get(uri);
};

const getListingChats = ({ listingId, from, to }) => {
  const uri = `${ServerConfig.api.userListingChats}/${listingId}/${from}/${to}`;
  return clientApi.get(uri);
};

const getChatLastMessage = (listingId, from, to) => {
  const uri = `${ServerConfig.api.userListingChats}/last/${listingId}/${from}/${to}`;
  return clientApi.get(uri);
};

let socket = null;
const initSocketConnection = (userId) => {
  console.log(`** New socket connection for ${userId}`);
  socket = io(ServerConfig.api.hostBrowser, {
    transports: ["websocket", "polling"],
    query: { user: `${userId}` },
  });
  console.log(`Connected socket...`);
};

const disconnectSocket = () => {
  console.log("Disconnected socket...");
  if (socket) socket.disconnect();
};

const subscribeUserOnline = (cb) => {
  if (!socket) return true;
  socket.on("users-online", (msg) => {
    console.log("Users online event received!");
    return cb(null, msg);
  });
};

const subscribeToChat = (cb) => {
  if (!socket) return true;
  socket.on("chat-message", (msg) => {
    console.log("Websocket event received!");
    return cb(null, msg);
  });
};

const subscribeToStatusSeen = (cb) => {
  if (!socket) return true;
  socket.on("chat-status-seen", (msg) => {
    console.log("Websocket status seen event received!");
    return cb(null, msg);
  });
};

const sendMessage = (type, message) => {
  if (socket) socket.emit(type, message);
};

const getBlockStatusByPostedUser = (currentUserId, postedUserId) => {
  const url = `${ServerConfig.api.blockUser}/${currentUserId}/${postedUserId}`;
  return clientApi.get(url);
};


export {
  getChats,
  getUserChats,
  getListingChats,
  getChatLastMessage,
  initSocketConnection,
  disconnectSocket,
  subscribeUserOnline,
  subscribeToChat,
  subscribeToStatusSeen,
  sendMessage,
  getBlockStatusByPostedUser
};
