import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { getMultipleListings } from "../../../services/listing.service";
import {
  getUserListings,
  patchUserInfo,
  getUserSettings,
  patchUserListings,
  getPublicUserProfile,
  getUserNotifications,
  sendVerificationEmail,
  getUserInfo,
  postUserSettings,
  postAppReport
} from "../../../services/userProfile.service";
import { setStatusMessage } from "../../actions";
import { ErrorCode } from "../../../utils/ErrorCode";
interface IUserProfile {
  listings: any;
  listingFull: any;
  updateUserStatus: any;
  settings: any;
  publicUserProfile: any;
  toggleBlockDrawer: any;
  userNotifications: any;
  showModalPopup: any;
  userInfo: any;
  unreadNotifications: any;
}

const initialState: IUserProfile = {
  listings: null,
  listingFull: null,
  updateUserStatus: null,
  settings: null,
  publicUserProfile: null,
  toggleBlockDrawer: null,
  userNotifications: null,
  showModalPopup: null,
  userInfo: null,
  unreadNotifications: null,
};

export const fetchUserListings = createAsyncThunk(
  "userProfile/listings",
  async (userId: any) => {
    const res = await getUserListings(userId);
    return res?.data || [];
  }
);

export const updateUserListings = createAsyncThunk(
  "userProfile/updateListings",
  async (payload: any) => {
    const res = await patchUserListings(payload);
    return res?.data || [];
  }
);

export const fetchUserListingsFull = createAsyncThunk(
  "userProfile/listingsFull",
  async (listings: Array<string>) => {
    const res = await getMultipleListings(listings);
    return res?.data || [];
  }
);

export const updateUserInfo = createAsyncThunk(
  "userProfile/updateUser",
  async (payload: any, thunkAPI:any) => {
    try {
      const res = await patchUserInfo(payload);
      thunkAPI.dispatch(
        setStatusMessage({
          type: "success",
          message: ErrorCode.PATCH_USER_INFO_SUCCESS,
        })
      );
      return res?.data || [];
    } catch(error: any) {
      thunkAPI.dispatch(
        setStatusMessage({
          type: "error",
          message:
            error?.response?.data?.message || ErrorCode.PATCH_USER_INFO_FAILED,
        })
      );
    }
  }
);

export const fetchUserInfo = createAsyncThunk(
  "userProfile/userInfo",
  async (id: any) => {
    const res = await getUserInfo(id);
    return res?.data || [];
  }
);

export const fetchUserSettings = createAsyncThunk(
  "userProfile/settings",
  async (id: any) => {
    const res = await getUserSettings(id);
    return res?.data || [];
  }
);

export const updateUserSettings = createAsyncThunk(
  "userProfile/updateSettings",
  async (payload: any, thunkAPI: any) => {
    try {
      const res = await postUserSettings(payload);
      thunkAPI.dispatch(
        setStatusMessage({
          type: "success",
          message: ErrorCode.POST_USER_SETTINGS_SUCCESS,
        })
      );
      return res?.data || [];
    } catch(error: any) {
      thunkAPI.dispatch(
        setStatusMessage({
          type: "error",
          message:
            error?.response?.data?.message || ErrorCode.POST_USER_SETTINGS_FAILED,
        })
      );
    }
  }
);

export const fetchPublicUserProfile = createAsyncThunk(
  "userProfile/publicUserProfile",
  async (id: any) => {
    const res = await getPublicUserProfile(id);
    return res?.data || [];
  }
);

export const fetchUserNotifications = createAsyncThunk(
  "userProfile/userNotifications",
  async (id: any) => {
    const res = await getUserNotifications(id);
    return res?.data || [];
  }
);

export const sendVerificationMail = createAsyncThunk(
  "userProfile/sendVerificationMail",
  async (email: any) => {
    const res = await sendVerificationEmail(email);
    return res?.data;
  }
);

const userProfileSlice = createSlice({
  name: "userProfile",
  initialState,
  extraReducers: {
    [fetchUserListings.fulfilled.type]: (
      state: any,
      { payload }: PayloadAction<any>
    ) => {
      return { ...state, listings: { ...payload } };
    },
    [updateUserListings.fulfilled.type]: (
      state: any,
      { payload }: PayloadAction<any>
    ) => {
      return { ...state, listings: { ...payload } };
    },

    [fetchUserListingsFull.fulfilled.type]: (
      state: any,
      { payload }: PayloadAction<any>
    ) => {
      return { ...state, listingFull: { ...payload } };
    },
    [updateUserInfo.pending.type]: (state: any) => {
      return { ...state, updateUserStatus: "PENDING" };
    },
    [updateUserInfo.fulfilled.type]: (state: any) => {
      return { ...state, updateUserStatus: "SUCCESS" };
    },
    [fetchUserSettings.fulfilled.type]: (
      state: any,
      { payload }: PayloadAction<any>
    ) => {
      return { ...state, settings: { ...payload } };
    },
    [updateUserSettings.fulfilled.type]: (
      state: any,
      { payload }: PayloadAction<any>
    ) => {
      return { ...state, settings: { ...payload } };
    },
    [fetchPublicUserProfile.fulfilled.type]: (
      state: any,
      { payload }: PayloadAction<any>
    ) => {
      return { ...state, publicUserProfile: { ...payload } };
    },
    [fetchUserNotifications.fulfilled.type]: (
      state: any,
      { payload }: PayloadAction<any>
    ) => {
      return {
        ...state,
        userNotifications: payload,
      };
    },
    [fetchUserInfo.fulfilled.type]: (
      state: any,
      { payload }: PayloadAction<any>
    ) => {
      return {
        ...state,
        userInfo: payload,
      };
    },
  },
  reducers: {
    toggleBlockDrawer(state, action) {
      return {
        ...state,
        toggleBlockDrawer: action.payload,
      };
    },
    showModalPopup(state, action) {
      return {
        ...state,
        showModalPopup: action.payload,
      };
    },
    unreadNotifications(state, action) {
      return {
        ...state,
        unreadNotifications: action.payload,
      }
    }
  },
});

export const { toggleBlockDrawer, showModalPopup, unreadNotifications } = userProfileSlice.actions;

export default userProfileSlice.reducer;
