import {
  Form,
  Input,
  Row,
  Col,
  Slider,
  Drawer,
  Button as AntButton,
  Typography,
} from "antd";
import { useRouter } from "next/router";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SearchOutlined, EnvironmentOutlined } from "@ant-design/icons";
import { Button } from "@components";
import { Locations } from "../locations";
import { saveSearch } from "@redux/actions";

const { Text } = Typography;

const interval = {
  "10": "",
  "20": "",
  "30": "",
  "40": "",
  "50": "",
  "60": "",
  "70": "",
  "80": "",
  "90": "",
  "100": "",
};

const SearchForm: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const router: any = useRouter();
  const { categories } = useSelector((state: any) => state.search);
  const { savedSearch = null } = useSelector((state: any) => state.listings);
  const appSettings: any = useSelector(
    (state: any) => state.appSettings?.web || null
  );
  const [distance, setDistance] = useState(null);

  const [form]: any = Form.useForm();
  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  const onFinish = (values: any) => {
    const { id }: any = Object.values(categories)[0];
    dispatch(saveSearch({ ...values, distance }));
    setVisible(false);
    router.push(`/listing?q=${id}`);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const isValidRouteForSearch = () => {
    const paths = [
      "login",
      "signUp",
      "chat",
      "userProfile",
      "newAd",
      "payment",
    ];

    const checker = (value: any) => router.pathname.includes(value);

    return paths.filter(checker).length <= 0;
  };

  const SearchForm = () => (
    <Form
      form={form}
      name='homeSearch'
      initialValues={{
        distance:
          savedSearch?.distance || appSettings?.nearByDistanceInKM || 100,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete='off'
      layout='vertical'
    >
      <Row gutter={[5, 5]} className='justify-center'>
        <Col xs={24} sm={14} md={14}>
          <Form.Item
            label=''
            name='address'
            rules={[{ type: "object" }]}
            className='mb-0'
          >
            {typeof window !== "undefined" && (
              <Locations
                onChange={(val: any, distanceVal: any = null) => {
                  // console.log("[address]", val, distanceVal);
                  form.setFieldsValue({ address: val });
                  setDistance(distanceVal);
                }}
                defaultValue=''
                component='home'
                defaultDistance={
                  distance ||
                  savedSearch?.distance ||
                  appSettings?.nearByDistanceInKM ||
                  100
                }
                hideDistance={false}
              />
            )}
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Form.Item label='' name='keyword' rules={[{}]} className='mb-0'>
            <Input placeholder='Search Nearby Ads' />
          </Form.Item>
        </Col>
        <Col xs={0} sm={6} md={2}>
          <Form.Item className='mb-0 text-right mt-2 sm:mt-0'>
            <Button type='primary' htmlType='submit'>
              Search
            </Button>
          </Form.Item>
        </Col>
        <Col xs={24} sm={0} md={0}>
          <Form.Item className='mb-0 text-right mt-2 sm:mt-0'>
            <Button
              htmlType='submit'
              className='ant-btn ant-btn-primary text-white bg-red-600 
                                 hover:bg-red-500 border-red-600 hover:border-red-500 
                                 w-full mt-6 text-lg h-auto'
            >
              Search
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
  return (
    <>
      <Row>
        <Col xs={0} md={24}>
          <SearchForm />
        </Col>
        <Col xs={24} md={0}>
          <>
            <Row className='flex justify-between items-center'>
              {isValidRouteForSearch() &&
                typeof window !== "undefined" &&
                localStorage.getItem("defaultAddress") && (
                  <Col xs={15} className='flex justify-center items-center'>
                    <EnvironmentOutlined className='mr-1 font-bold' />
                    <Text style={{ width: 170 }} ellipsis>
                      {localStorage.getItem("defaultAddress")}
                    </Text>
                  </Col>
                )}
              <Col xs={9}>
                {isValidRouteForSearch() && (
                  <AntButton
                    danger
                    block
                    className='rounded'
                    onClick={showDrawer}
                  >
                    Search Ads
                  </AntButton>
                )}
              </Col>
            </Row>
            <Drawer
              title='Search Ads'
              placement='right'
              onClose={onClose}
              visible={visible}
              width={"100%"}
            >
              <SearchForm />
            </Drawer>
          </>
        </Col>
      </Row>
    </>
  );
};

export default SearchForm;
