import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { UserOutlined, MessageOutlined } from "@ant-design/icons";
import { List, Avatar, Typography, Spin, Empty, Skeleton } from "antd";
import { useSession } from "next-auth/react";
import { ChatState } from "../../context/ChatProvider";
import { SocketState } from "../../context/SocketProvider";
import { getChatLastMessage } from "../../services/chat.service";
import { fetchUserChats } from "@redux/actions";
import { convertPostedDate } from "@helpers/timeFormat";
import { calculatesAgoTime } from "@helpers/textFormat";
import moment from "moment";

type Props = { fetchAgain: boolean };

export const MyChats: React.FC<Props> = ({ children, fetchAgain }) => {
  const [loading, setLoading] = useState(true);
  const { Title, Text } = Typography;
  const dispatch: any = useDispatch();
  const { data: session, status }: any = useSession();
  const chats = useSelector((state: any) => state?.chat?.chatsList || []);
  const {
    selectedChat,
    setSelectedChat,
    chats: contextChats,
    setChats,
    newListingChat,
    setNewListingChat,
    notification,
    setNotification
  } = ChatState();
  const { chatMessage, chatStatusSeen }: any = SocketState();
  const [userExtraData, setUserExtraData] = useState<any>([]);
  const [clearIndication, setClearIndication] = useState<any>();

  useEffect(() => {
    // When chat screen opened, clear global chat notifications
    setNotification([]);
  }, []);

  useEffect(() => {
    if (fetchAgain || chatStatusSeen?.to == session?.user?.id) {
      dispatch(fetchUserChats(session?.user?.id));
    }
  }, [fetchAgain, chatStatusSeen]);

  useEffect(() => {
    if (chatMessage) {
      // console.log(`[MyChat - chatMessage]: ${JSON.stringify(chatMessage)}`);
      let updatedNewChat: any = chatMessage;
      if (!!contextChats?.length) {
        let data: any = [...contextChats];
        const index = data.findIndex((u: any) => u.listing.id == chatMessage.listing?.id && (u.to.id == chatMessage.to || u.from.id == chatMessage.to));
        if (index != -1) {
          updatedNewChat = { ...updatedNewChat, _id: data[index]._id, id: data[index].id };
          // console.log(`[chatMessage - updatedNewChat]`, updatedNewChat);
          if (selectedChat) {
            if (selectedChat._id != updatedNewChat._id) {
              const item = { ...data[index], status: "X000", timestamp: moment()};
              data[index] = item;
              setChats([...data]);
            }
          } else {
            setSelectedChat(updatedNewChat);
          }
        }
        /*
        contextChats.map((u: any) => {
          if (
            u.listing.id == chatMessage.listing?.id &&
            u.from.id == chatMessage.to
          ) {
            updatedNewChat = { ...updatedNewChat, _id: u._id, id: u.id };
            // console.log('[selectedChat]', JSON.stringify(updatedNewChat));
            // If another chat is selected, do not disturb.
            if (selectedChat) {
              if (selectedChat.id != updatedNewChat.id) {
              }
            } else {
              setSelectedChat(updatedNewChat);
            }
          }
        }); */
      }
      // New chat from another user
      if (!updatedNewChat.id) {
        // setNotification([updatedNewChat, ...notification]); // TODO: Commented. Re look
        dispatch(fetchUserChats(session?.user?.id));
      }
    }
  }, [chatMessage]);

  useEffect(() => {
    setLoading(true);
    if (!!chats?.length) {  // TODO: Called on page focus every time.
      let userList: any[] = [];
      setUserExtraData([]);
      chats.map(
        // [{listing:{id,title,image}, users:[{from,to,lastMsg},{from,to,lastMsg}]]
        (info: any) => {
          if (info.listing) {
            const currentUserId = session?.user?.id;
            const userInvolved = info.users.filter(
              (f: any) => f.from.id == currentUserId || f.to.id == currentUserId
            );

            userInvolved.map((user: any) => {
              const isSameUser = user.from.id == currentUserId;
              const data = {
                _id: user._id,
                id: isSameUser ? user.to.id : user.from.id,
                name: isSameUser ? user.to.name : user.from.name,
                lastMessage: user.lastMsg || "",
                timestamp: user.lastMsgTime || "2022-07-18 12:20:13.320Z",
                status: user.lastMsgStatus || "",
                listing: info.listing,
                from: user.from,
                to: user.to,
                photo: isSameUser ? user.to.photo : user.from.photo
              };
              userList.push(data);
              // getExtraMessageData(data);
            });
          }
        }
      );
      const sortedList = userList.sort((a: any, b: any) => Date.parse(b.timestamp) - Date.parse(a.timestamp))
      setChats(sortedList);

      if (newListingChat && Object.keys(newListingChat).length > 0) {
        // console.log(`[newListingChat]`, JSON.stringify(newListingChat));
        let updatedNewChat = newListingChat;
        userList.map((u: any) => {
          if (
            u.listing.id == newListingChat.listing.id &&
            u.to.id == newListingChat.message.to
          ) {
            updatedNewChat = { ...updatedNewChat, _id: u._id, id: u.id };
          }
        });
        setNewListingChat(updatedNewChat);
        setSelectedChat(updatedNewChat);
      }
      setLoading(false);
    }
    setLoading(false);
  }, [chats]);

  useEffect(() => {
    if (!clearIndication) return;
    let data: any = [...contextChats];
    const index = data.findIndex((u: any) => u._id == clearIndication);
    if (index != -1) {
      const item = { ...data[index], status: "0XX0", timestamp: moment()};
      data[index] = item;
      setChats([...data]);
    }
  }, [clearIndication]);

  const getExtraMessageData = async (chatItem: any) => {
    setLoading(true);
    const res = await getChatLastMessage(chatItem.listing.id, chatItem.from.id, chatItem.to.id);
    if (res.data && res.data.lastMessage) {
      const extraData = { id: chatItem._id, message: res.data.lastMessage };
      let data: any = userExtraData;
      const exists = data.findIndex((v: any) => v.id === chatItem._id);
      if (exists != -1) {
        data[exists].message = res.data.lastMessage;
      } else {
        data.push(extraData);
      }
      setUserExtraData([...data]);
    }
    setLoading(false);
  };

  // '0X00' => Pending,sent,Received,Deleted
  const isLastMessageReceived = (chatItem: any) => {
    if (userExtraData.length > 0) {
      const msg = userExtraData.find((data: any) => data.id == chatItem._id && data.message != undefined);
      if (msg && msg.message && msg.message.status) {
        // console.log(`** Status: ${msg.message.status} | ${msg.message.from}`);
        if (msg.message.from == session?.user?.id || msg.message.status.charAt(2) == 'X') {
          return true;
        }
      }
      return false;
    }
    return false;
  };
  
  const showNewChatIndication = (chatItem: any) => {
    if (clearIndication == chatItem._id) return false;
    if (chatMessage) {
      if (chatItem.listing.id == chatMessage.listing?.id && chatItem.from.id == chatMessage.to) {
        return true;
      }
    }
    return !isLastMessageReceived(chatItem);
  };

  const getTimePosted = (timestamp: any) => {
    if (!timestamp) {
      return '--';
    }
    const timeDisplay = convertPostedDate(timestamp) == "Today" ?
                        calculatesAgoTime(timestamp) :
                        convertPostedDate(timestamp);
    return timeDisplay;
  };

  return (
    <Spin spinning={loading}>
      <Title level={4} className="pt-3 pl-2">Chats</Title>
      {!!contextChats?.length ? (
      <List
        className="chat-list-container overflow-y-auto"
        bordered={true}
        itemLayout="horizontal"
        dataSource={contextChats}
        loading={loading}
        renderItem={(item: any) => (
          <List.Item
            className={`p-0 m-0 ${selectedChat?._id === item._id ? "bg-slate-100" : ""}`}
            key={item._id}
            style={{ cursor: "pointer" }}
            onClick={() => {
              setSelectedChat(item);
              setClearIndication(item._id);
            }}
          >
            <Skeleton avatar title={false} loading={item.loading} active>
              <List.Item.Meta
                avatar={
                  item?.photo ? (
                    <Avatar src={item.photo} />
                  ) : (
                      <Avatar icon={<UserOutlined />} />
                    )
              }
                title={<span className="font-bold">{item.name}</span>}
                description={item.listing.title}
                className={`px-2 py-2 ${selectedChat?._id === item._id ? "border-l-4 border-red-500" : ""}`}
              />
              {/* {(showNewChatIndication(item)) &&
                <div className="mr-2"><MessageOutlined style={{ color: "red", fontSize: "16px" }} /></div>
              } */}
              {selectedChat?._id !== item._id && item.status && item.status.charAt(2) !== "X" &&
                <div className="mr-2"><MessageOutlined style={{ color: "red", fontSize: "16px" }} /></div>
              }
              <div className="mr-2 text-xs font-light text-slate-400">{getTimePosted(item.timestamp)}</div>
            </Skeleton>
          </List.Item>
        )}
      />) : <Empty description="No Chat history found"/> }
    </Spin>
  );
};

export default MyChats;
