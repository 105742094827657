import React from "react";
import {
  CreditCardOutlined,
  DollarCircleOutlined,
  WarningOutlined,
} from "@ant-design/icons";

const TipsforChat: React.FC = () => {
  return (
    <>
      <p className="font-bold mb-3">
        Loanrr is strictly a platform to facilitate goods exchange and cannot be
        held liable for any damages or stolen items
      </p>
      <ul>
        <li className="flex items-center border-b border-dotted border-red-200">
          <div className="mr-2 text-red-500 text-lg font-bold">
            <CreditCardOutlined />
          </div>
          <div>
            Be safe, Take necessary precautions while meeting with loaners and
            borrowers
          </div>
        </li>
        <li className="flex items-center border-b border-dotted border-red-200">
          <div className="mr-2 text-red-500 text-lg font-bold">
            <DollarCircleOutlined />
          </div>
          <div>Never give money or product in advance</div>
        </li>
        <li className="flex items-center border-b border-dotted border-red-200">
          <div className="mr-2 text-red-500 text-lg font-bold">
            <WarningOutlined />
          </div>
          <div>Report suspicious users to Loanrr</div>
        </li>
      </ul>
    </>
  );
};

export default TipsforChat;
