import axios from "axios";
import clientApi from "../api/clientApi";
import ServerConfig from "../config/ServerConfig";

const emailSignup = (payload) => {
  const uri = ServerConfig.api.register;
  return clientApi.post(uri, payload);
};

const oAuthSignup = (payload) => {
  const uri = ServerConfig.api.registerOAuth;
  return clientApi.post(uri, payload);
};

const generateOtp = (email) => {
  const uri = `${ServerConfig.api.urlBrowser}${ServerConfig.api.generateOtp}`;
  return new Promise((resolve, reject) => {
    axios.post(uri, {email}).then(res => {
      resolve(res);
    }, error => {
      reject(error?.response?.data?.message)
    })
  });
};

const verifyToken = (userId, otp) => {
  const uri = `${ServerConfig.api.urlBrowser}${ServerConfig.api.verifyOtp}?token=${otp}`;
  return new Promise((resolve, reject) => {
    axios.post(uri, {userId}).then(res => {
      resolve(res);
    }, error => {
      reject(error?.response?.data?.message)
    })
  });
};

const resetPassword = (userId, password) => {
  const uri = `${ServerConfig.api.urlBrowser}${ServerConfig.api.resetPasswordNoToken}?userId=${userId}`;
  return axios.post(uri, {password});
};

export {
  emailSignup,
  oAuthSignup,
  generateOtp,
  verifyToken,
  resetPassword
};