import AppConfig from '../config/AppConfig';
import aes from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';

const key = AppConfig.keyChatMessageAES;

export const DoEncrypt = (text) => {
  let encrypted = aes.encrypt(text, key).toString();
  return encrypted;
};

export const DoDecrypt = (cipher) => {
  if (!cipher) return;
  let bytes  = aes.decrypt(cipher, key);
  let decrypted = bytes.toString(CryptoENC);
  return decrypted;
};