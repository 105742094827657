import clientApi from "../api/clientApi";
import ServerConfig from "../config/ServerConfig";

const getUserListings = (userId) => {
  const uri = `${ServerConfig.api.userListings}/${userId}`;
  return clientApi.get(uri);
};

const patchUserListings = (payload) => {
  const uri = `${ServerConfig.api.userListings}/${payload.userId}`;
  return clientApi.patch(uri, payload);
};

const patchUserInfo = ({ firstName, lastName, mobilePhone, id }) => {
  const uri = `${ServerConfig.api.users}/${id}`;
  return clientApi.patch(uri, { firstName, lastName, mobilePhone });
};

const getUserInfo = (id) => {
  const uri = `${ServerConfig.api.users}/${id}`;
  return clientApi.get(uri);
};

const getUserSettings = (id) => {
  const uri = `${ServerConfig.api.userSettings}/${id}`;
  return clientApi.get(uri);
};

const postUserSettings = (payload) => {
  const uri = `${ServerConfig.api.userSettings}/${payload.user}`;
  return clientApi.post(uri, {...payload});
};

const getPublicUserProfile = (id) => {
  const uri = `${ServerConfig.api.publicUserProfile}/${id}`;
  return clientApi.get(uri);
};

const getUserNotifications = (id) => {
  const uri = `${ServerConfig.api.pushNotifications}/${id}`;
  return clientApi.get(uri);
};

const updateUserNotifications = (userId) => {
  const uri = `${ServerConfig.api.pushNotifications}/${userId}`;
  return clientApi.patch(uri);
};

const sendVerificationEmail = (email) => {
  const uri = `${ServerConfig.api.accountVerification}?email=${email}`;
  return clientApi.get(uri);
};

const deleteUserAccount = (userId) => {
  const uri = `${ServerConfig.api.deleteUserAccount}/${userId}`;
  return clientApi.post(uri);
};

const postAppReport = (payload) => {
  const uri = `${ServerConfig.api.reportIssue}`;
  return clientApi.post(uri, payload);
}

const getSubscription = () => {
  const uri = `${ServerConfig.api.subscription}`;
  return clientApi.get(uri);
}

const getSubscriptionById = (id) => {
  const uri = `${ServerConfig.api.subscription}/${id}`;
  return clientApi.get(uri);
}

const getUserSubscription = (id) => {
  // const uri = `${ServerConfig.api.userSubscriptions}/${id}`;
  const uri = `${ServerConfig.api.userSubscriptionsActive}/${id}`;  
  return clientApi.get(uri);
}

const createPaymentIntent = (userId, payload) => {
  const uri = `${ServerConfig.api.makePayment}/user/${userId}`;
  return clientApi.post(uri, payload);
}

export {
  getUserListings,
  patchUserInfo,
  getUserSettings,
  patchUserListings,
  getPublicUserProfile,
  getUserNotifications,
  updateUserNotifications,
  sendVerificationEmail,
  getUserInfo,
  postUserSettings,
  deleteUserAccount,
  postAppReport,
  getSubscription,
  getUserSubscription,
  createPaymentIntent,
  getSubscriptionById
};
