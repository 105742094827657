import React, { useContext, useEffect, useState } from "react";
import Link from "next/link";
import { useDispatch, useSelector } from "react-redux";
import { useSession } from "next-auth/react";
import {
  fetchListingChats,
  fetchUserChats,
  fetchPublicUserProfile,
  toggleBlockDrawer,
  fetchBlockStatusByPostedUser,
  showModalPopup
} from "@redux/actions";
import {
  Divider,
  Button as AntButton,
  Typography,
  Input,
  Avatar,
  Empty,
  Tooltip,
  Spin
} from "antd";
import { v4 as uuidv4 } from "uuid";
import {
  SendOutlined,
  CloseOutlined,
  InfoOutlined,
  UserOutlined,
  InfoCircleOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { DoEncrypt } from "../../helpers/aes";
import { ChatItem, ToggleUserBlock } from "@components";
import { ChatState } from "../../context/ChatProvider";
import { SocketState } from "../../context/SocketProvider";
import { sendChatMessage, sendChatStatusSeen } from "../../sockets";
import ScrollableFeed from "react-scrollable-feed";

const wait = (timeout: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, timeout);
  });
};

const { Text } = Typography;
type Props = { fetchAgain: any; setFetchAgain: any };
let selectedChatCompare: any = null;

export const SingleChat: React.FC<Props> = ({ fetchAgain, setFetchAgain }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [chatMessages, setChatMessages]: any = useState([]);
  const [text, setText]: any = useState("");
  const { data: session, status }: any = useSession();
  const [newChatReceived, setNewChatReceived] = useState(false);

  const chatListing: any = useSelector(
    (state: any) => state?.chat?.listing || []
  );
  const chats: any = useSelector((state: any) => state?.chat?.chatsList || []);
  const { publicUserProfile = null }: any = useSelector(
    (state: any) => state.userProfile
  );

  const { blockedByPostedUser = false }: any = useSelector(
    (state: any) => state?.chat
  );

  const { selectedChat, setSelectedChat, newListingChat, setNewListingChat } = ChatState();
  const { chatMessage } = SocketState();

  useEffect(() => {
    setChatMessages([]);
  }, []);

  useEffect(() => {
    // To avoid calling API in loop, check if chat is open
    if (!chats?.length && session?.user?.id) {
      dispatch(fetchUserChats(session.user.id));
    }
  }, [session]);

  useEffect(() => {
    // Can be a new chat or existing chat
    if (selectedChat && session?.user) {
      // console.log("[Message - Selected Chat]", JSON.stringify(selectedChat));
      if (chatMessage) {
        // console.log("[Chat Message Received]");
        onChatMessage(chatMessage);
      }
      // Do not fetch if it is a new chat
      if (selectedChat && selectedChat._id != null) {
        setLoading(true);
        selectedChatCompare = selectedChat;
        dispatch(
          fetchListingChats({
            listingId: selectedChat.listing.id,
            from: session.user.id,
            to: selectedChat.id,
          })
        );
        dispatch(fetchPublicUserProfile(selectedChat.id));
      }
    }
    return () => {
      //disconnectSocket();
    };
  }, [selectedChat, chatMessage]);

  useEffect(() => {
    if (chatListing && chatListing.length > 0) {
      setNewChatReceived(false);
      //console.log(`** chatListing: ${JSON.stringify(chatListing)}`);
      setChatMessages(chatListing);
      updateChatMessages(chatListing);
    }
    setLoading(false);
  }, [chatListing]);

  useEffect(() => {
    if (newListingChat && Object.keys(newListingChat).length > 0) {
      // console.log(`** newListingChat: ${JSON.stringify(newListingChat)}`);
      setSelectedChat(newListingChat);
      dispatch(fetchPublicUserProfile(newListingChat.message.to));
      if (newListingChat._id == null) {
        setNewChatReceived(true);
      }
      setChatMessages([newListingChat]);
      setLoading(false);
    }
  }, [newListingChat]);

  useEffect(() => {
    if (session?.user?.id && publicUserProfile?.id) {
      dispatch(
        fetchBlockStatusByPostedUser({
          currentUserId: session?.user?.id,
          postedUserId: publicUserProfile?.id,
        })
      );
    }
  }, [session?.user?.id, publicUserProfile?.id]);

  const sendMessageHandler = () => {
    if (selectedChat && session?.user && !newChatReceived) {
      // console.log("[Message - selected chat]", JSON.stringify(selectedChat));
      let message = {
        userId: session.user?.id,
        gid: uuidv4(),
        text: DoEncrypt(text),
        from: session.user?.id,
        to: selectedChat.id || selectedChat.message?.to,
        listing: {
          id: selectedChat.listing.id,
          title: selectedChat.listing.title,
          image: "",
        },
        image: "",
      };
      sendChatMessage(message);
      setChatMessages((chatMessages: any) => [...chatMessages, message]);
    } else if (newChatReceived) {
      setNewChatReceived(false);
      // console.log("[text2]", newListingChat);
      const { listing, message } = newListingChat;
      let messages: any[] = [];
      let listingMsg = {
        userId: session?.user?.id,
        gid: message.gid,
        text: listing.title,
        from: message.from,
        to: message.to,
        listing: {
          id: listing.id,
          title: listing.title,
          image: listing.image,
        },
        image: listing.image || "",
      };
      messages.push(listingMsg);
      listingMsg.text = DoEncrypt(listing.title);
      sendChatMessage(listingMsg);

      let msg = {
        userId: session?.user?.id,
        gid: uuidv4(),
        text: text,
        from: message.from,
        to: message.to,
        listing: {
          id: listing.id,
          title: listing.title,
          image: listing.image,
        },
        image: "",
      };
      messages.push(msg);
      msg.text = DoEncrypt(text);
      setChatMessages((chatMessages: any) => [...chatMessages, ...messages]);
      setNewListingChat(null);
      // Send after a delay to avoid creating duplicate list items
      wait(400).then(() => sendChatMessage(msg));
      setFetchAgain(true); // Let myChats know
    }
    setText("");
  };

  const onChatMessage = (msg: any) => {
    // console.log(`** Message received: ${JSON.stringify(msg)}`);
    // console.log('[Ids]', selectedChatCompare?._id, selectedChat?._id);

    // For new chat 'selectedChat?._id' is undefined
    if (msg.to == session?.user?.id && (selectedChat?._id == undefined || selectedChatCompare?._id == selectedChat?._id)) {
      let message = {
        gid: msg.gid,
        _id: msg._id,
        text: msg.text,
        from: msg.from,
        to: msg.to,
        listing: msg.listing,
      };
      setChatMessages((chatMessages: any) => [...chatMessages, message]);
    } else {
      setFetchAgain(!fetchAgain);
    }
  };

  /**
   * Scenario 1: When user chat screen is opened, if the message status is not received or not sent
   * update them all to received and sent
   */
  const updateChatMessages = (messages: any) => {
    // console.log(`[Update Messages - Status Seen]`);
    // If not received, not sent
    let msgsToUpdate = messages
      .filter(
        (msg: any) => msg.text.charAt(2) != "X" || msg.text.charAt(1) != "X"
      )
      .map((item: any) => item.gid);

    if (msgsToUpdate.length > 0) {
      const body = {
        chatIds: msgsToUpdate,
        to: selectedChat?.id || selectedChat?.message?.to,
      };
      // console.log(`[Chat message seen]`, body);
      sendChatStatusSeen(body);
    }
  };

  if (!selectedChat && !newListingChat && !!chats?.length) {
    return (
      <Empty description={<span>Select an item to see chat messages</span>} />
    );
  }

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      sendMessageHandler();
      setText("");
    }
  };

  return (
    <>
      {selectedChat && (
        <>
          <ToggleUserBlock />
          <div className="flex items-center justify-between">
            {!!chatMessages.length && selectedChat && (
              <Link href={`/listing/${selectedChat.listing.id}`}>
                <div style={{ cursor: "pointer" }}>
                  <Avatar
                    src={selectedChat.listing.image}
                    className="mx-2 my-2"
                  />
                  <Text className="font-bold text-lg hover:underline" 
                    >
                    {selectedChat.listing.title}
                  </Text>
                </div>
              </Link>
            )}
            <div className="flex items-center justify-center">
              <div
                style={{ cursor: "pointer" }}
                onClick={() =>
                  dispatch(showModalPopup({ title: "Tips for a safe Chat", type: "CHAT" }))
                }
              >
                <Avatar
                  icon={<InfoOutlined />}
                  className="mx-2 my-2 text-white bg-red-500 border shadow"
                />
              </div>
              <div
                style={{ cursor: "pointer" }}
                onClick={() =>
                  dispatch(toggleBlockDrawer(publicUserProfile?.id || null))
                }
              >
                <Avatar
                  icon={<UserOutlined />}
                  src={publicUserProfile?.photo}
                  className="mx-2 my-2 border shadow"
                />
              </div>
              <AntButton
                className="bg-white text-red-500 border-1 shadow-lg rounded-full"
                icon={<CloseOutlined />}
                onClick={() => setSelectedChat(null)}
              />
            </div>
          </div>
          <Divider className="m-0" />
          {blockedByPostedUser && (
            <div className="flex items-center w-auto">
              <Tooltip
                title={`You are blocked by ${publicUserProfile?.name}. Contact support for more information.`}
              >
                <div className="flex items-center justify-between bg-red-600 text-white text-center p-1 mt-1 mb-1 w-full rounded-lg">
                  <StopOutlined className="p-1" />
                  <span className="w-full">
                    You are blocked. Chat not allowed
                  </span>
                  <InfoCircleOutlined className="p-1" />
                </div>
              </Tooltip>
            </div>
          )}
          <Spin spinning={loading}>
            <div className="py-2 bg-slate-50 messages-container">
              <ScrollableFeed forceScroll={true}>
                {!!chatMessages.length &&
                  [...chatMessages].map((item: any, index: any) => (
                    <div
                      key={index}
                      className={`flex px-2 ${
                        session?.user?.id === item.from ? "justify-end" : ""
                      }`}
                    >
                      {(item.text || item.image) && (
                        <div
                          className={`${
                            session?.user?.id === item.from
                              ? "chat-bubble sb5"
                              : "chat-bubble sb6"
                          }`}
                        >
                          <ChatItem {...item} />
                        </div>
                      )
                      // <div className="w-full flex justify-center">
                      //   <Empty description={<span>No messages</span>} />
                      // </div>
                      }
                    </div>
                  ))}
              </ScrollableFeed>
            </div>
          </Spin>
          <div className="">
            <Input
              addonAfter={
                <AntButton
                  onClick={sendMessageHandler}
                  className="chat-send-btn"
                  icon={<SendOutlined />}
                  disabled={blockedByPostedUser}
                />
              }
              placeholder="Type a message"
              defaultValue=""
              maxLength={200}
              onKeyDown={handleKeyDown}
              value={text}
              onChange={(e) => setText(e.target.value)}
              disabled={blockedByPostedUser}
            />
          </div>
        </>
      )}
      {!!chats?.length && !selectedChat && <Empty description={<span>Select an item to see chat messages</span>} />}
    </>
  );
};

export default SingleChat;
