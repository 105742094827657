import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSession } from "next-auth/react";
import {
  updateUserBlock,
  updateUserUnblock,
  fetchBlockedByPostedStatus,
  resetReportUser
} from "@redux/actions";
import { Button as AntButton, Avatar, Spin, Typography } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { convertJoinDate } from "@helpers/timeFormat";

const { Text } = Typography;

export const UserInfo = ({changeState}: any) => {
  const dispatch = useDispatch();
  const { data: session, status }: any = useSession();
  const currentUser: any = session?.user || null;
  const { blockedByPosted = false }: any = useSelector(
    (state: any) => state.listings
  );
  const { publicUserProfile = null }: any = useSelector(
    (state: any) => state.userProfile
  );

  const [blockUserText, setBlockUserText] = useState("Block User");

  useEffect(() => {
    if (blockedByPosted) {
      setBlockUserText("Unblock User");
    } else {
      setBlockUserText("Block User");
    }
  }, [blockedByPosted]);

  useEffect(() => {
    if (currentUser?.id && publicUserProfile?.id) {
      dispatch(
        fetchBlockedByPostedStatus({
          currentUserId: currentUser.id,
          postedUserId: publicUserProfile.id,
        })
      );
    }
  }, [currentUser?.id, publicUserProfile?.id]);

  const handleSubmit = () => {
    const payload: any = {
      userId: publicUserProfile?.id || null,
      blockedBy: currentUser?.id || null,
      reason: "Rude Chat",
    };
    if (blockedByPosted) {
      dispatch(updateUserUnblock(payload));
    } else {
      dispatch(updateUserBlock(payload));
    }
  };

  const memberSince = () => {
    if (publicUserProfile?.createdAt) {
      let createdDate = new Date(
        publicUserProfile.createdAt
      ).toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });
      return convertJoinDate(createdDate);
    }
    return null;
  };

  return (
    <>
      <div className="flex flex-col justify-center items-center">
        <Avatar
          size={64}
          icon={<UserOutlined />}
          src={publicUserProfile?.photo || ""}
          className=""
        />
        {!publicUserProfile && <Spin />}
        <h1 className="font-bold text-md">{publicUserProfile?.name || ""}</h1>
        <h1 className="text-sm text-gray-500 mb-2">
          {publicUserProfile?.email || ""}
        </h1>
        <h1 className="text-sm text-gray-500 mb-2 border rounded p-2">
          Memeber Since: <strong>{memberSince()}</strong>
        </h1>
        <Text
          type={publicUserProfile?.active ? "success" : "danger"}
          className="border px-2 rounded font-bold"
        >
          {publicUserProfile?.active ? "Active" : "Inactive"}
        </Text>
        <div className="flex">
          <AntButton
            className="mt-5 bg-red-500 text-white font-bold disabled:opacity-75 mr-4"
            onClick={() => {
                dispatch(resetReportUser())
                changeState('R')
            }}
          >
            Report User
          </AntButton>
          <AntButton
            className="mt-5 bg-red-500 text-white font-bold disabled:opacity-75 mr-4"
            onClick={handleSubmit}
          >
            {blockUserText}
          </AntButton>
        </div>
      </div>
    </>
  );
};

export default UserInfo;
