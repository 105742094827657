import Script from "next/script";
import { Input } from "antd";
import React, { useEffect, useState } from "react";
import PlacesAutocomplete, {
  Suggestion,
  geocodeByAddress,
} from "react-places-autocomplete";
import styles from "./styles.module.css";
import AppConfig from "src/config/AppConfig";

type Props = {
  address: string;
  clearAddress: any;
  onChange: any;
  onAddressSelect: any;
  onCoordinatesUpdate: any;
};

export const LocationSearch: React.FC<Props> = (props: Props) => {
  const [address, setAddress] = useState(props.address);

  useEffect(() => {
    if (!address) {
      props.clearAddress();
    }
  }, [address]);

  const onAddressSelect = (addr: string): void => {
    geocodeByAddress(addr)
      .then(async (results: google.maps.GeocoderResult[]) => {
        const verified = results[0].address_components;
        const formatted = results[0].formatted_address;
        console.log(results, verified, formatted);
        let formattedAddress = {
          street: "",
          city: "",
          subRegion: "",
          region: "",
          country: "",
        };
        verified.forEach((entry) => {
          if (entry.types.indexOf("route") > -1) {
            formattedAddress.street = entry.long_name;
          }
          if (entry.types.indexOf("locality") > -1) {
            formattedAddress.city = entry.long_name;
          }
          if (entry.types.indexOf("administrative_area_level_1") > -1) {
            formattedAddress.region = entry.long_name;
          }
          if (entry.types.indexOf("country") > -1) {
            formattedAddress.country = entry.short_name;
          }
        });
        setAddress(`${formatted}`);
        props.onAddressSelect(formatted);
        props.onCoordinatesUpdate({
          coordinates: [
            results[0].geometry.location.lat(),
            results[0].geometry.location.lng(),
          ],
        });
      })
      .then((latLng: any) => {
        if (latLng) {
          props.onCoordinatesUpdate({ coords: [latLng.lat, latLng.lng] });
        }
      })
      .catch((error: any) => {
        console.error("Error", error);
      });
  };

  const args = {
    getInputProps: Object,
    getSuggestionItemProps: Object,
    suggestions: Array,
    loading: Boolean,
  };

  // const mapApiKey = AppConfig.googleMapsApiKey;
  const script = `https://maps.googleapis.com/maps/api/js?key=${AppConfig.googleMapsApiKey}&libraries=places`;

  return (
    <>
      <Script src={script} strategy="beforeInteractive"></Script>
      <PlacesAutocomplete
        onChange={setAddress}
        onSelect={onAddressSelect}
        value={address}
        shouldFetchSuggestions={address.length > 2}
      >
        {({ getInputProps, getSuggestionItemProps, suggestions, loading }) => (
          <React.Fragment>
            <Input
              {...getInputProps({
                id: "address-input",
                placeholder: 'Enter your location'
              })}
            />
            <div className={styles.autocomplete}>
              {loading ? (
                <div className={styles.suggestionItem}>Loading...</div>
              ) : null}
              {suggestions.map((suggestion: Suggestion) => {
                const className = suggestion.active
                  ? styles.suggestionItemActive
                  : styles.suggestionItem;
                const spread: any = {
                  ...getSuggestionItemProps(suggestion, {
                    className,
                  }),
                };
                return (
                  <div {...spread} key={suggestion.id}>
                    <div>
                      {suggestion.description?.length > 47
                        ? `${suggestion.description.substr(0, 47)}...`
                        : suggestion.description}
                    </div>
                  </div>
                );
              })}
            </div>
          </React.Fragment>
        )}
      </PlacesAutocomplete>
    </>
  );
};
