import React, { useEffect } from "react";
import { notification } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { resetNotificationMessage } from "@redux/actions";

notification.config({
  placement: 'topRight',
  top : 50,
  duration: 4,
  rtl: false,
  maxCount: 2
})

export const Notifications = () => {
  const dispatch = useDispatch();
  const { type, message, description } = useSelector((state: any) => state.notifications);

  useEffect(() => {
    if (!type) return;
    
    switch (type) {
      case "success":
        notification.success({message, description, key: message});
        break;
      case "info":
        notification.info({message, description, key: message});
        break;
      case "warning":
        notification.warning({message, description, key: message});
        break;
      case "error":
        notification.error({message, description, key: message});
        break;
      default:
        break;
    }
    dispatch(resetNotificationMessage());
  }, [type]);
  return null;
};
