import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  getNearby,
  getFavorites,
  getCategorySpecific,
  getFeaturedListing,
  getCarousel,
  getRelatedListings,
  postUserBlock,
  postUserUnblock,
  getUserBlockStatus,
  postSendReportUser
} from "../../../services/listing.service";
import { setStatusMessage } from "../../actions";
import { ErrorCode } from "../../../utils/ErrorCode";

const carouselData = [{"active":true,"title":"Parking and Garage","description":"Parking and Garage spaces","imageUrl":"https://ik.imagekit.io/raolnr/carousel/new/4.jpeg","category":"6241c6be040524d3723051bf","categoryName":"Parking & Storage","id":"6241c797b809f797d4d60553"},{"active":true,"title":"Parking and Garage","description":"Parking and Garage spaces","imageUrl":"https://ik.imagekit.io/raolnr/carousel/new/5.jpeg","category":"6241c6be040524d3723051bf","categoryName":"Parking & Storage","id":"6241c8a0b809f797d4d60556"},{"active":true,"title":"Wedding rentals","description":"Wedding rentals","imageUrl":"https://ik.imagekit.io/raolnr/carousel/new/6.jpeg","category":"60979b63040524d372c5b9ea","categoryName":"Weddings & Events","id":"62497ce2040524d3723307e1"}];

export const getNearbyListings = createAsyncThunk(
  "listings/nearby",
  async (
    payload: {
      location: { coordinates: any };
      radius: Number;
      page: Number;
      __ref: any;
    },
    thunkAPI: any
  ) => {
    try {
      if (payload?.__ref === 'search') {
        thunkAPI.dispatch(resetNearByListing())
      }
      const res = await getNearby({ ...payload });
      if (!res.data?.results?.length) {
        /*
        thunkAPI.dispatch(
          setStatusMessage({
            type: "info",
            message: ErrorCode.NEARBY_ADS_NO_RESPONSE,
          })
        ); */
      } /*
      thunkAPI.dispatch(
        setStatusMessage({
          type: "success",
          message: ErrorCode.NEARBY_ADS_SUCCESS_RESPONSE,
        })
      ); */
      return {...res.data, __ref: payload?.__ref || null};
    } catch (error: any) {
      thunkAPI.dispatch(
        setStatusMessage({
          type: "error",
          message:
            error?.response?.data?.message || ErrorCode.GENERIC_ERROR_MESSAGE,
        })
      );
    }
  }
);
export const getUserFavorites = createAsyncThunk(
  "listings/favorites",
  async (userId) => {
    const res = await getFavorites(userId);
    return res.data.favorites;
  }
);
export const fetchFeaturedListing = createAsyncThunk(
  "listings/featured",
  async (payload: {
    location: { coordinates: any };
    radius: Number;
    page: Number;
  }) => {
    const res = await getFeaturedListing({ ...payload });
    return res?.data || null;
  }
);
export const fetchCarousel = createAsyncThunk("listings/carousel", async () => {
  const res = await getCarousel();
  return res?.data || null;
});

export const fetchRelatedListings = createAsyncThunk(
  "listings/relatedListings",
  async (title: any) => {
    const res = await getRelatedListings(title);
    return res?.data || null;
  }
);

export const getCategorySpecificListings = createAsyncThunk(
  "listings/category",
  async (payload: {
    categoryId: String;
    limit: Number;
    page: Number;
    radius: Number;
    location: any;
    title: any;
  }) => {
    const res = await getCategorySpecific({ ...payload });
    return res.data?.results;
  }
);

export const updateUserBlock = createAsyncThunk(
  "listings/userBlock",
  async (payload: any, thunkAPI: any) => {
    try {
      const res = await postUserBlock(payload);
      thunkAPI.dispatch(
        setStatusMessage({
          type: "success",
          message: ErrorCode.POST_USER_BLOCK_SUCCESS,
        })
      );
      return res.data;
    } catch(error: any) {
      thunkAPI.dispatch(
        setStatusMessage({
          type: "error",
          message:
            error?.response?.data?.message || ErrorCode.POST_USER_BLOCK_FAILED,
        })
      );
    }
  }
);

export const updateUserUnblock = createAsyncThunk(
  "listings/userUnblock",
  async (payload: any, thunkAPI: any) => {
    try {
      const res = await postUserUnblock(payload);
      thunkAPI.dispatch(
        setStatusMessage({
          type: "success",
          message: ErrorCode.POST_USER_UNBLOCK_SUCCESS,
        })
      );
      return res.data;
    } catch(error: any) {
      thunkAPI.dispatch(
        setStatusMessage({
          type: "error",
          message:
            error?.response?.data?.message || ErrorCode.POST_USER_UNBLOCK_FAILED,
        })
      );
    }
  }
);

export const fetchBlockedByPostedStatus = createAsyncThunk(
  "listings/blockedByPostedStatus",
  async ({ currentUserId, postedUserId }: any) => {
    const res = await getUserBlockStatus(postedUserId, currentUserId);
    return res.data;
  }
);

export const sendReportUser = createAsyncThunk(
  "listings/sendReportUser",
  async (payload: any, thunkAPI: any) => {
    try {
      const res = await postSendReportUser(payload);
      thunkAPI.dispatch(
        setStatusMessage({
          type: "success",
          message: ErrorCode.POST_SEND_REPORT_USER_SUCCESS,
        })
      );
      return res.data;
    } catch(error: any) {
      thunkAPI.dispatch(
        setStatusMessage({
          type: "error",
          message:
            error?.response?.data?.message || ErrorCode.POST_SEND_REPORT_USER_FAILED,
        })
      );
    }
  }
);

const initialState: any = {
  nearByListing: {
    loading: false,
    data: null,
    error: null,
    totalResults: 0,
    totalPages: 0,
  },
  userFavorites: null,
  categorySpecificListings: null,
  featuredListing: null,
  carousel: carouselData,
  relatedListings: null,
  savedSearch: null,
  userBlock: null,
  userUnblock: null,
  isPostUserBlocked: null,
  blockedByPosted: null,
  reportUser: null,
};

const listingsSlice = createSlice({
  name: "listings",
  initialState: initialState,
  extraReducers: {
    [getNearbyListings.pending.type]: (
      state: any,
      { payload }: PayloadAction<any>
    ) => {
      return {
        ...state,
        nearByListing: { ...state.nearByListing, loading: true },
      };
    },
    [getNearbyListings.fulfilled.type]: (
      state: any,
      { payload }: PayloadAction<any>
    ) => {
      console.log('[payload?.__ref]', payload?.__ref)
      if (payload?.__ref === 'search') {
        return {
          ...state,
          nearByListing: {
            ...state.nearByListing,
            loading: false,
            data: [...payload?.results],
            error: !payload?.results?.length ? "EMPTY" : null,
            totalResults: payload?.totalResults || 0,
            totalPages: payload?.totalPages || 0,
          },
        };
      }
      return {
        ...state,
        nearByListing: {
          ...state.nearByListing,
          loading: false,
          data: [...(state.nearByListing?.data || []), ...(payload?.results || [])],
          error: !payload?.results?.length ? "EMPTY" : null,
          totalResults: payload?.totalResults || 0,
          totalPages: payload?.totalPages || 0,
        },
      };
    },
    [getUserFavorites.fulfilled.type]: (
      state: any,
      { payload }: PayloadAction<any>
    ) => {
      return { ...state, userFavorites: [...payload] };
    },
    [getCategorySpecificListings.pending.type]: (
      state: any,
      { payload }: PayloadAction<any>
    ) => {
      return {
        ...state,
        categorySpecificListings: null,
        nearByListing: {
          loading: false,
          data: null,
          error: null,
          totalResults: 0,
          totalPages: 0,
        },
      };
    },
    [getCategorySpecificListings.fulfilled.type]: (
      state: any,
      { payload }: PayloadAction<any>
    ) => {
      return {
        ...state,
        categorySpecificListings: [...payload],
        nearByListing: {
          loading: false,
          data: null,
          error: null,
          totalResults: 0,
          totalPages: 0,
        },
      };
    },
    [fetchFeaturedListing.fulfilled.type]: (
      state: any,
      { payload }: PayloadAction<any>
    ) => {
      return { ...state, featuredListing: [...payload] };
    },
    [fetchCarousel.fulfilled.type]: (
      state: any,
      { payload }: PayloadAction<any>
    ) => {
      return { ...state, carousel: [...payload] };
    },
    [fetchRelatedListings.fulfilled.type]: (
      state: any,
      { payload }: PayloadAction<any>
    ) => {
      return { ...state, relatedListings: [...(payload?.results || [])] };
    },
    [updateUserBlock.fulfilled.type]: (
      state: any,
      { payload }: PayloadAction<any>
    ) => {
      return { ...state, blockedByPosted: true };
    },
    [updateUserUnblock.fulfilled.type]: (
      state: any,
      { payload }: PayloadAction<any>
    ) => {
      return { ...state, blockedByPosted: false };
    },
    [fetchBlockedByPostedStatus.fulfilled.type]: (
      state: any,
      { payload }: PayloadAction<any>
    ) => {
      return { ...state, blockedByPosted: payload.blocked };
    },
    [sendReportUser.pending.type]: (
      state: any,
      { payload }: PayloadAction<any>
    ) => {
      return { ...state, reportUser: 'loading...' };
    },
    [sendReportUser.fulfilled.type]: (
      state: any,
      { payload }: PayloadAction<any>
    ) => {
      return { ...state, reportUser: 'success' };
    },
    [sendReportUser.rejected.type]: (
      state: any,
      { payload }: PayloadAction<any>
    ) => {
      return { ...state, reportUser: 'error' };
    },
  },
  reducers: {
    saveSearch: (state, { payload }) => {
      state.savedSearch = { ...payload };
    },
    resetNearByListing: (state) => {
      state.nearByListing = {
        loading: false,
        data: null,
        error: null,
        totalResults: 0,
        totalPages: 0,
      };
      state.categorySpecificListings = null;
    },
    resetReportUser: (state) => {
      state.reportUser = null;
    }
  },
});

const { reducer, actions } = listingsSlice;

export const { saveSearch, resetNearByListing, resetReportUser } = actions;

export default reducer;
