import React from "react";
import { Typography } from "antd";
import { DoDecrypt } from '../../helpers/aes';

const { Text } = Typography;

export const ChatItem: React.FC = (props: any) => {
  return (
    <>
      {props?.image && (
        <div className="flex justify-center items-center">
          <img src={props.image} alt="card-img" className="rounded w-40 h-40" style={{objectFit: 'cover'}}/>
        </div>
      )}
      {props?.text && <>{DoDecrypt(props.text)}</>}
    </>
  );
};
