import React from "react";
import { useSession } from "next-auth/react";

export const Authorized: React.FC<{ children: any; type: any; postedBy: any }> = ({
  children,
  type,
  postedBy
}: any) => {

  const { data: session }: any = useSession();

  if (type === "HIDE_FOR_USER") {
    if (session?.user?.id === postedBy) {
      return null;
    }
    return <>{children}</>;
  }

  if (type === "SHOW_FOR_USER") {
    if (session?.user?.id === postedBy) {
      return <>{children}</>;
    }
    return null;
  }
  
  return null;
};
