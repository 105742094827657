import React from "react";
import Image from "next/image";
import Link from "next/link";

export const Logo: React.FC = () => {
  return (
    <div className="flex items-center">
      <div style={{ cursor: "pointer" }}>
        <Link href="/">
          <Image
            data-test="icon"
            src="/icons/icon_512.png"
            alt="Loanrr Classifieds"
            width="40"
            height="40"
          />
        </Link>
      </div>
      <div className="pl-1 pt-0 md:pt-0 block">
        <h1 className="text-base lg:text-lg font-bold leading-4">LOANRR</h1>
      </div>
    </div>
  );
};
