import React from "react";
import {
  PictureOutlined,
  FileTextOutlined,
  CreditCardOutlined,
  WarningOutlined,
  TagOutlined,
  BankOutlined,
} from "@ant-design/icons";

const TipsforAds: React.FC = () => {
  return (
    <>
      <p className="font-bold mb-3">
        Loannr is strictly a platform to facilitate goods exchange and cannot be
        held liable for any damage or stolen items.
      </p>
      <ul>
        <li className="flex items-center border-b border-dotted border-red-200">
          <div className="mr-2 text-red-500 text-lg font-bold">
            <PictureOutlined />
          </div>
          <div>Add good quality picture that shows the product clearly</div>
        </li>
        <li className="flex items-center border-b border-dotted border-red-200">
          <div className="mr-2 text-red-500 text-lg font-bold">
            <FileTextOutlined />
          </div>
          <div>
            Provide good description of the product and your lending terms
          </div>
        </li>
        <li className="flex items-center border-b border-dotted border-red-200">
          <div className="mr-2 text-red-500 text-lg font-bold">
            <CreditCardOutlined />
          </div>
          <div>
            For quick transactions, set a realistic and reasonable price
          </div>
        </li>
        <li className="flex items-center border-b border-dotted border-red-200">
          <div className="mr-2 text-red-500 text-lg font-bold">
            <BankOutlined />
          </div>
          <div>
            To ensure smooth transaction and avoid issues we recommend the
            lender take a deposit of no more than 10% as collateral
          </div>
        </li>
        <li className="flex items-center border-b border-dotted border-red-200">
          <div className="mr-2 text-red-500 text-lg font-bold">
            <TagOutlined />
          </div>
          <div>Offer operational help to the borrower as necessary</div>
        </li>
        <li className="flex items-center border-b border-dotted border-red-200">
          <div className="mr-2 text-red-500 text-lg font-bold">
            <WarningOutlined />
          </div>
          <div>
            In case of a dispute, report the incident to authorities and avoid
            arguments and fights
          </div>
        </li>
      </ul>
    </>
  );
};

export default TipsforAds;
