import React, { useEffect, useState } from "react";
import Script from "next/script";
import {
  AutoComplete,
  Input,
  Tooltip,
  notification,
  Modal,
  Button as AntButton,
  Slider,
  Typography,
  Row,
  Col,
} from "antd";
import {
  SendOutlined,
  SearchOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
import { Button, Notifications } from "@components";
import AppConfig from "src/config/AppConfig";
import { useDispatch, useSelector } from "react-redux";
import { setStatusMessage } from "@redux/actions";
import { ErrorCode } from "../../utils/ErrorCode";
import { LocationState } from "../../context/LocationProvider";

const { Title, Text } = Typography;

// TODO: use https://www.npmjs.com/package/react-geolocated
const getLocationName = (addressComponents: any) =>
  addressComponents
    ?.filter(({ types }: any) => {
      const result = [
        "locality",
        "administrative_area_level_1",
        "country",
      ].includes(types[0]);
      return result;
    })
    .map(({ long_name }: any) => long_name)
    .join(", ") || "";

// Ref: https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete
// https://developers.google.com/maps/documentation/javascript/supported_types
export const googleAutocomplete = async (text: any) =>
  new Promise((resolve, reject) => {
    if (!text) {
      return reject("Invalid text input");
    }

    // for use in things like GatsbyJS where the html is generated first
    if (typeof window === "undefined") {
      return reject("Invalid window object");
    }

    try {
      if (window.google) {
        new window.google.maps.places.AutocompleteService().getPlacePredictions(
          {
            input: text,
            componentRestrictions: { country: "ca" },
            types: ["address"],
          },
          resolve
        );
      }
    } catch (e) {
      reject(e);
    }
  });

export const Locations: React.FC<{
  onChange: any;
  defaultValue: any;
  component: string;
  defaultDistance: any;
  hideDistance: any;
}> = ({
  onChange,
  defaultValue = null,
  component,
  defaultDistance = null,
  hideDistance = true,
}: any) => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [predictions, setPredictions] = useState([]);
  const [mappedAddress, setMappedAddress]: any = useState(null);
  const [mappedLocationName, setMappedLocationName]: any = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [sliderDistance, setSliderDistance] = useState(defaultDistance);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    const dAddress:any = localStorage?.getItem("defaultAddress") || null;
    setSearchValue(dAddress)
    setIsModalOpen(false);
  };

  const { setDefaultAddress, setFormattedAddress } = LocationState();

  const defaultAddress: any =
    defaultValue ||
    (typeof window !== "undefined" &&
      localStorage?.getItem("defaultAddress")) ||
    null;

  useEffect(() => {
    if (defaultAddress) {
      onSelectHandler(defaultAddress);
    } else if (!defaultAddress && navigator.geolocation) {
      setLocationAddress();
      // openNotification();
    }
  }, [defaultAddress]);

  const saveLocation = (addressComponents: any, formattedAddress: any) => {
    if (component == "newad" || component == "editad") {
      return;
    }
    localStorage?.setItem("defaultAddress", addressComponents);
    localStorage?.setItem("formattedAddress", formattedAddress);
    setDefaultAddress(addressComponents);
    setFormattedAddress(formattedAddress);
  };

  const fetchGeoLocation = async ({ latitude, longitude }: any) => {
    try {
      const resJson = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&sensor=true&key=${AppConfig.googleMapsApiKey}`
      );
      const { results } = await resJson.json();
      // console.log(`[Location]`, JSON.stringify(results[0]));
      const formattedAddress = {
        address: getLocationName(results[0]?.address_components) || null,
        geo: {
          lat: latitude,
          lng: longitude,
        },
      };
      const locationName = getLocationName(results[0].address_components);
      // console.log('[locationName]', locationName)
      setMappedLocationName(locationName);
      setMappedAddress(formattedAddress);
      saveLocation(locationName, JSON.stringify(formattedAddress));
      setSearchValue(locationName);
      // onSelectHandler(locationName, 'geoLocation');
    } catch (err) {
      console.log("[Error]", err);
    }
  };

  const openNotification = () => {
    console.log(`** Open Notification`);
    const key = "request-geolocation";
    const btn = (
      <Button
        className='bg-red-600 hover:bg-red-700 rounded border-red-500'
        type='primary'
        onClick={() => {
          setLocationAddress();
          notification.close(key);
        }}
      >
        Grant Permission
      </Button>
    );
    let payload: any = {
      message: "Location Permission",
      description: "Please grant Location access for better experience.",
      btn,
      key,
      duration: 0,
    };
    notification.open(payload);
  };

  const setLocationAddress = () => {
    navigator.geolocation.getCurrentPosition(
      ({ coords }: any) => {
        fetchGeoLocation(coords);
      },
      (error: any) => {
        dispatch(
          setStatusMessage({
            type: "error",
            message: `${ErrorCode.USER_DENIED_GEOLOCATION}`,
            duration: 5
          })
        );
      }
    );
  };

  const onChangeHandler = async (data: any) => {
    setSearchValue(data);
    // console.log('[Location Change]', JSON.stringify(data));
    setMappedAddress({ address: data });
    // onChange({ address: data });
    if (data.length > 3) {
      const results: any = await googleAutocomplete(data);
      if (results) {
        // console.log('[Auto Complete]', JSON.stringify(results));
        const mappedResults = results.map((item: any) => {
          const locArr = item?.description?.split(",") || [];
          return {
            ...item,
            value: item.description,
            label: (
              <p className='border-b'>
                {locArr?.[0]}
                <br />
                <i className='text-xs'>
                  {[locArr?.[1], locArr?.[2]].filter((i) => i).join(",")}
                </i>
              </p>
            ),
          };
        });
        setPredictions(mappedResults);
      }
    }
  };

  const isMyScriptLoaded = (url: any) => {
    if (!url) url = "http://xxx.co.uk/xxx/script.js";
    var scripts = document.getElementsByTagName("script");
    for (var i = scripts.length; i--; ) {
      if (scripts[i].src == url) return true;
    }
    return false;
  };

  const onSelectHandler = (address: any, type: any = null) => {
    if (
      !isMyScriptLoaded(
        `https://maps.googleapis.com/maps/api/js?key=${AppConfig.googleMapsApiKey}&language=en&libraries=places`
      )
    ) {
      const s = document.createElement("script");
      s.type = "text/javascript";
      s.src = `https://maps.googleapis.com/maps/api/js?key=${AppConfig.googleMapsApiKey}&language=en&libraries=places`;
      document.head.appendChild(s);
    }
    
    if (!window?.google) {
      return;
    }
    const formatAddress =
      (typeof window !== "undefined" &&
        localStorage?.getItem("formattedAddress")) ||
      null;
      
    if (!type && formatAddress) {
      setMappedAddress(JSON.parse(formatAddress));
      // onChange(JSON.parse(formatAddress));
      setSearchValue(defaultAddress);
      return;
    }
    // console.log('[formatAddress]', type, formatAddress)
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address }, (results, status) => {
      if (status == "OK") {
        const formattedAddress = {
          address: results?.[0]?.formatted_address || null,
          geo: {
            lat: results?.[0]?.geometry?.location?.lat?.() || null,
            lng: results?.[0]?.geometry?.location?.lng?.(),
          },
        };
        setMappedAddress(formattedAddress);
        // onChange(formattedAddress);
        // console.log('[address]', address)
        setSearchValue(address || "");
        setMappedLocationName(address || "");
        // saveLocation(address, JSON.stringify(formattedAddress));
        // To fix over query limit issue
        localStorage?.setItem("defaultAddress", address);
        localStorage?.setItem("formattedAddress", JSON.stringify(formattedAddress));
      } else {
        setMappedAddress({ address });
        setSearchValue(address || "");
        // onChange({ address });
        if (status == google.maps.GeocoderStatus.OVER_QUERY_LIMIT) {
          dispatch(
            setStatusMessage({
              type: "warning",
              message: `${ErrorCode.LOCATION_QUERY_LIMIT_EXCEEDED}`,
            })
          );
          // alert("Failed to get Geo Location. Location query limit exceeded");
        } else {
          dispatch(
            setStatusMessage({
              type: "error",
              message: `${ErrorCode.FAILED_GEO_LOCATION}`,
            })
          );
          // alert("Failed to get Geo Location " + status);
        }
      }
    });
  };

  const onApplyHandler = () => {
    // console.log("[mappedAddress]", mappedLocationName, mappedAddress, sliderDistance);
    const dAddress = localStorage?.getItem("defaultAddress") || null;
    const fAddress = localStorage?.getItem("formattedAddress") || {};
    onChange(mappedAddress || fAddress, sliderDistance);
    saveLocation(mappedLocationName || dAddress, JSON.stringify(mappedAddress));
    setIsModalOpen(false);
  };

  return (
    <>
      <Notifications />
      <div onClick={showModal}>
        <Tooltip title={defaultAddress}>
          <div
            className='flex border px-2 py-1 rounded cursor-pointer select-none items-center justify-between'
            style={{ height: 32 }}
          >
            <div
              className='flex items-center'
              style={{ width: "calc(100% - 50px)" }}
            >
              <EnvironmentOutlined className='mr-1 font-bold' />{" "}
              {!hideDistance ? (
                <Text className='truncate'>{defaultAddress}</Text>
              ) : (
                <Text className='truncate'>{searchValue}</Text>
              )}
            </div>
            {!hideDistance && (
              <div className='border-l p-1 ml-1' style={{ width: "50px" }}>
                {defaultDistance}KM
              </div>
            )}
          </div>
        </Tooltip>
      </div>
      <Modal
        title='Location Search'
        visible={isModalOpen}
        footer={null}
        onCancel={handleCancel}
      >
        <div style={{ minHeight: "22rem" }}>
          <div>
            <Title level={5} className='m-0'>
              Location:
            </Title>
            <Input.Group compact className='flex relative locations'>
              <SearchOutlined
                className='absolute z-10 border-0'
                style={{ top: 10, left: 7 }}
              />
              <AutoComplete
                value={searchValue}
                options={predictions}
                style={{ width: "100%" }}
                onSelect={(address: any) => {
                  localStorage?.removeItem("formattedAddress");
                  onSelectHandler(address);
                }}
                onSearch={onChangeHandler}
                placeholder='Location'
                onFocus={(event: any) => event.target.select()}
              />
            </Input.Group>
            <button
              onClick={() => setLocationAddress()}
              className='underline text-red-700 py-2'
            >
              Use my current location
            </button>
          </div>
          <Row className='items-center mt-3'>
            <Col xs={18}>
              {!hideDistance && (
                <>
                  <Title level={5} className='m-0'>
                    Distance: {sliderDistance}KM
                  </Title>
                  <Slider
                    step={10}
                    min={20}
                    max={100}
                    onChange={(val: any) => {
                      setSliderDistance(val);
                    }}
                    value={sliderDistance}
                    className='ml-0 mt-0'
                  />
                </>
              )}
            </Col>
            <Col xs={6} className='text-right'>
              <Button onClick={() => onApplyHandler()}>Apply</Button>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};
