import { createSlice } from "@reduxjs/toolkit";

interface INotifications {
  type: null;
  message: null;
  description: null;
}

const initialState: INotifications = {
  type: null,
  message: null,
  description: null
};

const notificationsSlice = createSlice({
  name: "Notifications",
  initialState,
  extraReducers: {},
  reducers: {
    setNotificationMessage(state, { payload }) {
      return {
        ...state,
        type: payload.type,
        message: payload.message,
        description: payload.description
      };
    },
    resetNotificationMessage(state) {
      return {
        ...state,
        type: null,
        message: null,
        description: null
      };
    },
  },
});

export const { setNotificationMessage, resetNotificationMessage } =
  notificationsSlice.actions;

export default notificationsSlice.reducer;
