import clientApi from "../api/clientApi";
import ServerConfig from "../config/ServerConfig";

const getNearby = (data) => {
  const uri = `${ServerConfig.api.searchListingsMultiple}?location=${data.location.coordinates[0]},${data.location.coordinates[1]}&radius=${data.radius}&limit=12&page=${data.page}&title=${data?.title || ''}`;
  return clientApi.get(uri);
};

const getFavorites = (userId) => {
  const uri = `${ServerConfig.api.userListings}/${userId}`;
  return clientApi.get(uri);
};

const getCategorySpecific = (data) => {
  const uri = `${ServerConfig.api.searchCategoryListings}?categoryId=${data.categoryId}&limit=${data.limit || 10}&page=${data.page}&radius=${data.radius}&location=${data.location.coordinates[0]},${data.location.coordinates[1]}`;
  return clientApi.get(uri);
};

const deleteListing = (id) => {
  const uri = `${ServerConfig.api.manageListings}/${id}`;
  return clientApi.delete(uri);
};

const getMultipleListings = (listings) => {
  const uri = `${ServerConfig.api.getMultipleListings}`;
  return clientApi.post(uri, { listings: listings });
};

const getFeaturedListing = (data) => {
  const url = `${ServerConfig.api.featuredListing}?location=${data.location.coordinates[0]},${data.location.coordinates[1]}&radius=${data.radius}&limit=10&page=${data.page}`;
  return clientApi.get(url);
};

const getCarousel = () => {
  const url = `${ServerConfig.api.carousel}?active=true`;
  return clientApi.get(url);
};

const getRelatedListings = (title) => {
  // const url = `${ServerConfig.api.related}?title=${title}&includeFeatured=true`;
  const url = `${ServerConfig.api.related}?title=${title}&includeFeatured=false`; // TODO: includeFeatured for next release
  return clientApi.get(url);
};

const postUserBlock = (payload) => {
  const url = `${ServerConfig.api.blockUser}`;
  return clientApi.post(url, payload);
};

const postUserUnblock = (payload) => {
  const url = `${ServerConfig.api.unblockUser}`;
  return clientApi.post(url, payload);
};

const getUserBlockStatus = (currentUserId, postedUserId) => {
  const url = `${ServerConfig.api.blockUser}/${currentUserId}/${postedUserId}`;
  return clientApi.get(url);
};

const postSendReportUser = (payload) => {
  const url = `${ServerConfig.api.reportUser}/${payload.reportedBy}`;
  return clientApi.post(url, payload);
};

export {
  getNearby,
  getFavorites,
  getCategorySpecific,
  getMultipleListings,
  getFeaturedListing,
  getCarousel,
  getRelatedListings,
  postUserBlock,
  postUserUnblock,
  getUserBlockStatus,
  postSendReportUser,
  deleteListing
};
