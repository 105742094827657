import React, { useCallback, useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Upload,
  message,
  notification,
  Typography,
  Spin,
} from "antd";
import {
  UserOutlined,
  CameraOutlined,
  CheckCircleTwoTone,
  WarningTwoTone,
  CloseCircleTwoTone,
} from "@ant-design/icons";
import { signOut } from "next-auth/react";
import {
  postUploadFileApi,
  updateProfilePic,
} from "../../services/newAd.service";
import spamFilter from "../../utils/spamFilter";
import { useDispatch, useSelector } from "react-redux";
import { sendVerificationMail } from "@redux/slices/userProfile";
import AppConfig from "../../config/AppConfig";
import { useRouter } from "next/router";
import { resetOtp, fetchUserInfo } from "@redux/actions";
import { ErrorCode } from "../../utils/ErrorCode";

const { Text } = Typography;

export const getBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const ProfileImage: React.FC<{}> = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const userInfo = useSelector((state: any) => state.userProfile.userInfo);
  const [disableSendEmailButton, setDisableSendEmailButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userAvatarLetters, setUserAvatarLetters] = useState("");
  const [userProfilePic, setUserProfilePic] = useState(userInfo?.photo);

  useEffect(() => {
    if (userInfo && userInfo.name) {
      let initials = "L";
      const nameSplit = userInfo.name.toUpperCase().split(' ') || ["L"];
      if (nameSplit.length == 1) {
        initials = nameSplit[0] ? nameSplit[0].charAt(0) : "L";
      } else {
        initials = nameSplit[0].charAt(0) + nameSplit[1].charAt(0);
      }
      setUserAvatarLetters(initials);
    }
  }, [userInfo]);

  const sendVerificationEmail = () => {
    setDisableSendEmailButton(true);
    if (userInfo.email) {
      dispatch(sendVerificationMail(userInfo.email));
    }
  };

  const openNotification = () => {
    const key = "resend-email";
    const btn = (
      <Button
        className='bg-red-600 hover:bg-red-700 rounded border-red-500'
        type='primary'
        onClick={() => {
          sendVerificationEmail();
          notification.close(key);
        }}
      >
        Resend Email
      </Button>
    );
    let payload: any = {
      message: "Account Unverified",
      description:
        "Please verify your Account. Check you Email for verification link.",
      key,
      duration: 0,
    };
    if (!disableSendEmailButton) {
      const description =
        "Please verify your Account. Check you email for verification link. Click here to resend email.";
      payload = { ...payload, btn, description };
    }
    notification.open(payload);
  };

  useEffect(() => {
    if (userInfo && !userInfo.verified) {
      openNotification();
    }
  }, [userInfo]);

  const uploadChangeHandler = useCallback(
    async (e: any) => {
      try {
        setLoading(true);
        const [file] = e.target.files;
        const body = await getBase64(file);
        const folder = `${userInfo.name.replace(/ /g, "")}-${userInfo.id.slice(-5)}`;
        const today = new Date();
        const suffix = `${today.getDate()}${today.getMonth()}${today.getFullYear()}`;
        const payload = {
          name: `${userInfo.name.replace(/ /g, "")}_${suffix}.png`,
          type: "user",
          folder,
          body,
          predict: true,
        };
        const response = await postUploadFileApi(payload);
        // console.log("[response?.data]", response?.data);
        const { predictions = [], url = null } = response?.data || [];
        if (!!predictions.length && predictions[0].probability > 0.4) {
          const words = predictions[0].className.split(/,| /);
          const isSensitive = words.some((item: any) => {
            if (item && spamFilter.isSensitive(item.trim())) {
              return true;
            }
          });
          if (isSensitive) {
            message.error(ErrorCode.SENSITIVE_IMAGE_FOUND);
            return null;
          }
        }
        message.success(ErrorCode.POST_UPLOAD_FILE_API_SUCCESS);
        await updateProfilePic({ id: userInfo.id, url });
        message.success(ErrorCode.UPDATE_PROFILE_PIC_SUCCESS);
        setUserProfilePic(url);
        dispatch(fetchUserInfo(userInfo.id));
        // console.log("[result?.data]", result?.data);
      } catch (error: any) {
        message.error(
          error?.response?.data?.message ||
            ErrorCode.POST_UPLOAD_FILE_API_FAILED
        );
      } finally {
        setLoading(false);
      }
    }, [userInfo]);

  const beforeUpload = () => {
    return false;
  };

  const openResetPassword = () => {
    dispatch(resetOtp);
    router.push({
      pathname: "/forgotPassword",
      query: { screen: "profile", userEmail: userInfo.email },
    });
  };

  const logout = () => {
    signOut({ callbackUrl: AppConfig.clientUrlBrowser });
  };

  return (
    <div className='flex justify-center flex-col items-center'>
      <Spin tip='uploading...' spinning={loading}>
        <div className='relative'>
          {!userInfo && (
            <Avatar
              size={128}
              icon={<UserOutlined />}
              className='flex items-center justify-center border'
            />
          )}
          {userInfo && (
            <Avatar
              alt={userInfo.name || ""}
              size={128}
              src={userProfilePic || userInfo.photo}
              className='flex items-center justify-center'
            >{userAvatarLetters}</Avatar>
          )}
          <div>
            <input
              type='file'
              name='file'
              id='file'
              className='inputfile profile-upload'
              accept='image/x-png,image/jpeg'
              onChange={(e) => uploadChangeHandler(e)}
              // disabled={filesList.length >= 6 || !folder}
            />
            <label
              className='text-xs font-normal text-center absolute right-2 bottom-2 rounded-full font-bold mr-2 shadow-lg bg-slate-100 backdrop-opacity-10'
              htmlFor='file'
              style={{ width: 30, height: 30 }}
            >
              <Button
                icon={
                  <CameraOutlined style={{ fontSize: "18px", color: "red" }} />
                }
                className='border-0 rounded-full'
              />
            </label>
          </div>
        </div>
      </Spin>
      <div className='text-center px-5 py-5 space-y-1'>
        {userInfo && !userInfo.verified && (
          <div className='border rounded hover:bg-red-500'>
            <CloseCircleTwoTone twoToneColor='red' className='mt-1' />{" "}
            <Text className='text-xs'>
              Unverified. Check your inbox for verification link
            </Text>
          </div>
        )}
        {userInfo && userInfo.verified && (
          <div>
            <CheckCircleTwoTone twoToneColor='#52c41a' /> <Text>Verified</Text>
          </div>
        )}
        <h1 className='font-bold text-md'>{userInfo && userInfo.name}</h1>
        <h1 className='text-sm text-gray-500'>{userInfo && userInfo.email}</h1>
        <h1 className='text-sm text-gray-500'>{userInfo && userInfo.phone}</h1>
      </div>
      {userInfo && (
        <div className='flex justify-between mt-1 flex-col'>
          <Button
            className='bg-red-600 hover:bg-red-700 rounded border-red-500 w-full'
            type='primary'
            onClick={logout}
          >
            Logout
          </Button>
          <Button
            className='mt-2 w-full'
            type='link'
            onClick={openResetPassword}
          >
            <Text className='text-red-500 underline'>Reset Password</Text>
          </Button>
        </div>
      )}
    </div>
  );
};
