import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSession } from "next-auth/react";
import {
  Typography,
  Button as AntButton,
  Drawer,
  Radio,
  Input,
  Space,
  message,
  Spin,
} from "antd";
import { breakpoint } from "../../helpers/homeUtils";
import { sendReportUser } from "@redux/actions";
import { Button } from "@components";

const { Title, Text } = Typography;
const { TextArea } = Input;

export const ReportUser = ({ changeState }: any) => {
  const dispatch = useDispatch();
  const { data: session, status }: any = useSession();
  const currentUser: any = session?.user || null;
  const { publicUserProfile = null }: any = useSelector(
    (state: any) => state.userProfile
  );
  const { reportUser = null }: any = useSelector(
    (state: any) => state.listings
  );

  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const reportUserHandler = () => {
    dispatch(
      sendReportUser({
        type: "user",
        user: publicUserProfile?.id || null,
        reportedBy: currentUser?.id || null,
        title: subject,
        description,
      })
    );
  };
  useEffect(() => {
    if(reportUser === 'success') {
        changeState("R")
    }
  }, [reportUser])
  return (
    <Spin tip="Sending..." spinning={reportUser === 'loading'}>
      <div className="flex flex-col justify-center">
        <Input
          value={subject}
          onChange={(e) => setSubject(e.target.value.trim())}
          placeholder="Subject here..."
          maxLength={100}
        />
        <TextArea
          showCount
          maxLength={500}
          style={{ height: 75 }}
          onChange={(e) => setDescription(e.target.value.trim())}
          placeholder="Write description here..."
          className="mt-3 w-full"
          value={description}
        />
        <div className="flex">
          <AntButton
            className="mt-5 bg-white-500 text-red-500 font-bold disabled:opacity-75"
            block
            onClick={() => changeState("U")}
          >
            Cancel
          </AntButton>
          <AntButton
            className="mt-5 ml-2 bg-red-500 text-white font-bold disabled:opacity-75"
            block
            disabled={!(subject && subject.length > 1 && description && description.length > 2)}
            onClick={reportUserHandler}
          >
            Send
          </AntButton>
        </div>
      </div>
    </Spin>
  );
};

export default ReportUser;
