import moment from 'moment';

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

export const timeFormat = (timeStamp) => {
  if (timeStamp) {
    if (moment(timeStamp).isValid()) {
      return '';
    }
    if (moment().diff(moment.unix(timeStamp.seconds), 'days') == 0) {
      return moment.unix(timeStamp.seconds).format('H:mm');
    }
    return moment.unix(timeStamp.seconds).fromNow();
  }
  return ' ';
};

export const convertPostedDate = createdDate => {
  const date = new Date(createdDate);
  const today = new Date();
  if (date.getMonth() == today.getMonth() && date.getDate() == today.getDate()) {
    return 'Today';
  }
  return monthNames[date.getMonth()] + " " + date.getDate();
};

export const convertJoinDate = createdDate => {
  const date = new Date(createdDate);
  const today = new Date();
  if (date.getMonth() == today.getMonth() && date.getDate() == today.getDate()) {
    return 'Today';
  }
  return `${monthNames[date.getMonth()]} ${date.getFullYear()}`;
};

export const getDiffCurrentDate = postedDate => {
  const currentDate = new Date();
  const date = new Date(postedDate);
  let diffTime = Math.abs(currentDate- date)/1000;
  const diffDays = Math.floor(diffTime / 86400);
  diffTime -= diffDays * 86400;

  const diffHours = Math.floor(diffTime / 3600) % 24;
  diffTime -= diffHours * 3600;

  let diffYears = (currentDate.getTime() - date.getTime()) / 1000;
  diffYears /= 60 * 60 * 24;
  diffYears = Math.abs(Math.round(diffYears / 365.25));

  let diffMonths = (currentDate.getTime() - date.getTime()) / 1000;
  diffMonths /= 60 * 60 * 24 * 7 * 4;
  diffMonths = Math.abs(Math.round(diffMonths));
  const diffMinutes = Math.floor(diffTime / 60) % 60;

  diffTime = Math.abs(currentDate.getTime() - date.getTime()); 
  const diffSeconds = Math.ceil(diffTime / 1000);

  return {
    seconds: diffSeconds,
    minutes: diffMinutes,
    hours: diffHours,
    days: diffDays,
    months: diffMonths,
    years: diffYears
  };
};

export const getUpdatedDateText = (type, count) => {
  return count + " " + type;
  /*
  let retValue = "";
  if (count === 1) {
    retValue = count + " " + type;
  } else {
    retValue = count + " " + type + "s";
  }
  return retValue;
  */
};

export const calculateAgoTime = postedDate => {
  if (postedDate == "") {
    return "- -";
  }
  const diffDate = getDiffCurrentDate(postedDate);
  let retValue = "";
  if (diffDate.years > 0) {
    retValue = getUpdatedDateText("yr", diffDate.years);
  } else if (diffDate.months > 0) {
    retValue = getUpdatedDateText("mo", diffDate.months);
  } else if (diffDate.days > 0) {
    retValue = getUpdatedDateText("d", diffDate.days);
  } else if (diffDate.hours > 0) {
    retValue = getUpdatedDateText("hr", diffDate.hours);
  } else if (diffDate.minutes) {
    retValue = getUpdatedDateText("min", diffDate.minutes);
  } else if (diffDate.seconds) {
    retValue = getUpdatedDateText("sec", diffDate.seconds);
  }
  return retValue;
};

export const calculateAgoTimeFull = postedDate => {
  if (postedDate == "") {
    return "- -";
  }
  const diffDate = getDiffCurrentDate(postedDate);
  let retValue = "";
  if (diffDate.years > 0) {
    retValue = getUpdatedDateText("year(s)", diffDate.years);
  } else if (diffDate.months > 0) {
    retValue = getUpdatedDateText("month(s)", diffDate.months);
  } else if (diffDate.days > 0) {
    retValue = getUpdatedDateText("day(s)", diffDate.days);
  } else if (diffDate.hours > 0) {
    retValue = getUpdatedDateText("hour(s)", diffDate.hours);
  } else if (diffDate.minutes) {
    retValue = getUpdatedDateText("min", diffDate.minutes);
  } else if (diffDate.seconds) {
    retValue = getUpdatedDateText("sec", diffDate.seconds);
  }
  return `${retValue} ago`;
};