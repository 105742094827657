import React, { useEffect, useState } from "react";
import Head from "next/head";
import { useSession, signIn, signOut } from "next-auth/react";
import { useRouter } from "next/router";
import { Layout, Row, Col, Button, Typography, Popover, Badge, Tooltip } from "antd";
import Icon, {
  BellFilled,
  BellOutlined,
  ClockCircleFilled,
  MessageOutlined,
} from "@ant-design/icons";
import Link from "next/link";
import {
  Logo,
  StatusMessages,
  Notifications,
  Button as Lbutton,
  ModalPopup,
} from "@components";
import { useDispatch, useSelector } from "react-redux";
import { setStatusMessage, fetchUserNotifications, fetchAppSettings } from "@redux/actions";
import { MenuDrawer } from "./MenuDrawer";
import SearchForm from "./SearchForm";
import { calculateAgoTime } from "../../helpers/timeFormat";
import { ChatState } from "../../context/ChatProvider";
import { SocketState } from "../../context/SocketProvider";

const { Header: LayoutHead, Content, Footer } = Layout;
const { Text } = Typography;

const content = (messages: any) => {
  if (!messages?.length) {
    return <div>No new notifications</div>;
  }
  return (
    <div>
      {messages?.length > 5 ? [...messages?.slice(5)] : [...messages]
        .reverse()
        .map((item: any) => (
          <div key={item._id} className="flex justify-between max-w-lg leading-4 py-2 border-b">
            <div className="flex">
              <div>
                <BellFilled className="text-red-500 mr-2" />
              </div>
              <div className="text-left">
                <div className="font-bold">{item?.title || ""}</div>
                <div
                  className="text-slate-400 text-xs truncate"
                  style={{ maxWidth: 280 }}
                >
                  {item?.body || ""}
                </div>
              </div>
            </div>
            <div
              style={{ minWidth: 45 }}
              className="text-slate-500 flex items-center"
            >
              <ClockCircleFilled className="mr-1" />
              {calculateAgoTime(item?.receivedAt || "")}
            </div>
          </div>
        ))}
      <div className="flex justify-end w-full my-2">
        <Link href="/userProfile/notifications">
          <Lbutton>See More..</Lbutton>
        </Link>
      </div>
    </div>
  );
};

export const Header: React.FC<{page: String}> = (props) => {
  const page = props.page;
  const dispatch = useDispatch();
  const { data: session }: any = useSession();
  const router: any = useRouter();
  const { userNotifications = [] }: any = useSelector(
    (state: any) => state.userProfile
  );
  
  const { notification } = ChatState();
  const { chatMessage } = SocketState();
  const [chatNotification, setChatNotification] = useState(false);
  const [activeNotifications, setActiveNotifications] = useState<any[]>([]);

  useEffect(() => {
    dispatch(fetchAppSettings());
  }, []);

  useEffect(() => {
    // console.log("[userNotifications]", JSON.stringify(userNotifications));
    if (!!userNotifications?.length && !!userNotifications[0].notifications?.length) {
      const active = userNotifications[0].notifications.filter((item: any) => (item.hasOwnProperty("read") && item.read == false));
      setActiveNotifications([...active]);
    }
  }, [userNotifications]);

  useEffect(() => {
    if (!router?.pathname.includes("chat") && chatMessage) {
      setChatNotification(chatMessage ? true : false);
      // Show chat notification
      dispatch(
        setStatusMessage({
          type: "info",
          message: `New Chat Message received`,
          duration: 5
        }));
    }
  }, [chatMessage, notification]);

  useEffect(() => {
    if (session?.user?.id) {
      dispatch(fetchUserNotifications(session.user.id));
    }
  }, [session?.user?.id]);

  useEffect(() => {
    if (session?.error === "SESSION_EXPIRED") {
      signOut();
      router.push("/login");
    }
  }, [session]);

  return (
    <>
      {page != 'listing' &&
      <Head>
        <title>Loanrr Classifieds | Rent or Loan anything you can think of</title>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />

        {/* COMMON TAGS */}
        <meta charSet="utf-8" />
        {/* Search Engine */}
        <meta name="description" content="Loanrr is a platform that is solely based on allowing people to rent their items totally free." />
        <meta name="keywords" content="loanrr.ca, loanrr, Online retal, online renatl canada, canada rental online, loanr, rent online, rent mobiles online, rent books online, rent movie dvd's online, kindle, kindle fire hd, kindle e-readers, ebooks, computers, laptop, toys, trimmers, watches, fashion jewellery, home, kitchen, small appliances, beauty, Sports, Fitness &amp; Outdoors"></meta>
        <meta name="image" content="https://www.loanrr.ca/icons/icon_512.png" />
        {/* <!-- Twitter --> */}
        <meta name="twitter:url" content="https://www.loanrr.ca/" />
        <meta property="twitter:domain" content="loanrr.ca"></meta>
        <meta property="twitter:url" content="https://www.loanrr.ca"></meta>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Loanrr Classifieds" />
        <meta name="twitter:description" content="Loanrr is a platform that is solely based on allowing people to rent their items totally free." />
        <meta name="twitter:image:src" content="https://www.loanrr.ca/icons/icon_1200_600.png" />
        {/* Schema.org for Google */}
        <meta itemProp="name" content="Loanrr Classifieds" />
        <meta itemProp="description" content="Loanrr is a platform that is solely based on allowing people to rent their items totally free." />
        <meta itemProp="image" content="https://www.loanrr.ca/icons/icon_512.png" />
        {/* Open Graph general (Facebook, Pinterest & Google+) */}
        <meta name="og:title" content="Loanrr Classifieds" />
        <meta name="og:description" content="Loanrr is a platform that is solely based on allowing people to rent their items totally free." />
        <meta name="og:image" content="https://www.loanrr.ca/icons/icon_1200_600.png" />
        <meta name="og:url" content="https://www.loanrr.ca/" />
        <meta name="og:site_name" content="Loanrr" />
        <meta name="fb:app_id" content="335057945000398" />
        <meta name="og:type" content="website" />
        <meta name="google" content="nositelinkssearchbox" key="sitelinks" />
        <meta name="google" content="notranslate" key="notranslate" />
      </Head>}
      <ModalPopup />
      <LayoutHead
        className="h-auto bg-white items-center leading-4 px-5 lg:px-12 py-3 shadow-md mb-2 md:sticky md:top-0"
        style={{ zIndex: 100 }}
      >
        <StatusMessages />
        <Notifications />
        <Row gutter={[15, 15]}>
          <Col
            xs={{ span: 9, order: 1 }}
            md={{ span: 4, order: 1 }}
            lg={{ span: 4, order: 1 }}
          >
            <Logo />
          </Col>
          <Col
            xs={{ span: 24, order: 3 }}
            md={{ span: 12, order: 2 }}
            lg={{ span: 12, order: 2 }}
            style={{paddingTop: '4px'}}
          >
            <SearchForm />
          </Col>
          <Col
            xs={{ span: 15, order: 2 }}
            md={{ span: 8, order: 3 }}
            lg={{ span: 8, order: 3 }}
            className="flex items-center justify-end"
          >
            {session?.user && (
              <>
                <Link href="/newAd">
                  <button className="post-new-ad mr-1"><span>Post Ad</span></button>
                </Link>
                <Link href="/chat">
                  <Badge dot={chatNotification} offset={[-15, 3]}>
                    <Button
                      type='link' size='large'
                      className='text-red-500 hover:text-blue-500 active:text-red-500'
                      icon={
                        <Tooltip title='Chat'>
                          <MessageOutlined
                            data-test='icon'
                            className={`${chatNotification ? 'text-2xl animate-bounce' : 'text-2xl'}`}
                          />
                        </Tooltip>
                      }
                    />
                  </Badge>
                </Link>
                <Popover
                  placement="bottomRight"
                  title="Notifications"
                  content={content(activeNotifications)}
                  trigger="click"
                  arrowPointAtCenter
                >
                  <Badge count={activeNotifications?.length} offset={[-15, 2]}>
                    <Button
                      type='link' size='large'
                      className='text-red-500 hover:text-blue-500 active:text-red-500 mr-1'
                      icon={
                        <Tooltip title='Notifications'>
                          <BellOutlined
                            data-test='icon'
                            className='text-2xl'
                          />
                        </Tooltip>
                      }
                    />
                  </Badge>
                </Popover>
              </>
            )}
            {!session && (
              <Link href="/login">
                <Button danger className="text-black">
                  Log In
                </Button>
              </Link>
            )}
            {session?.user && <MenuDrawer />}
          </Col>
        </Row>
      </LayoutHead>
    </>
  );
};
