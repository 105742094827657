import clientApi from "../api/clientApi";
import ServerConfig from "../config/ServerConfig";

const postUploadFileApi = (payload) => {
  const uri = `${ServerConfig.api.files}`;
  return clientApi.post(uri, payload);
};

const updateProfilePic = ({ id, url }) => {
  const uri = `${ServerConfig.api.users}/${id}`;
  return clientApi.patch(uri, {
    photo: url,
  });
};

export { postUploadFileApi, updateProfilePic };
