import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSession } from "next-auth/react";
import {
  Typography,
  Button as AntButton,
  Drawer,
  Radio,
  Input,
  Space,
  message,
} from "antd";
import { breakpoint } from "../../helpers/homeUtils";
import { sendProductReport, resetReportData, fetchUserInfo } from "@redux/actions";
import { Button } from "@components";

const { Title, Text } = Typography;
const { TextArea } = Input;

export const ReportThisAd = ({ id }: any) => {
  const dispatch = useDispatch();
  const { data: session, status }: any = useSession();
  const currentUser: any = session?.user || null;
  const { data, report } = useSelector((state: any) => state.manage);
  const userInfo = useSelector((state: any) => state.userProfile?.userInfo);

  const [category, setCategory] = useState("Offensive Content");
  const [visible, setVisible] = useState(false);
  const [comment, setComment] = useState("");
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    console.log("[report]", report);
    if (report) {
      setCategory("Offensive Content");
      setComment("");
      setLoading(false);
      setVisible(false);
      dispatch(resetReportData());
    }
  }, [report]);

  useEffect(() => {
    if (currentUser?.id) {
      dispatch(fetchUserInfo(currentUser.id));
    }
  }, [currentUser]);

  const onChange = (e: any) => {
    setComment(e.target.value);
  };

  const isAccountVerified = () => {
    return (currentUser && currentUser.verified) || (userInfo && userInfo.verified);
  };

  const handleReportProduct = () => {
    if (!currentUser || (currentUser && !currentUser.id)) {
      message.warning("Login to view this feature");
      return;
    }
    if (!isAccountVerified()) {
      message.warning(
        "Please verify your account to use this feature. Check your inbox for email"
      );
      return;
    } else {
      setVisible(true);
    }
  };
  const handleSubmit = () => {
    const payload = {
      type: "listing",
      reportedBy: currentUser.id,
      listing: id,
      title: category,
      description: comment,
    };
    setLoading(true);
    dispatch(sendProductReport(payload));
  };
  return (
    <div className="mt-2 ml-0 lg:ml-2 w-full">
      <Button block onClick={handleReportProduct}>
        <Text className="text-white uppercase">Report This Ad</Text>
      </Button>
      <Drawer
        title="Rate Your Experience"
        width={["xs", "sm"].includes(breakpoint()) ? "100%" : "35%"}
        onClose={() => setVisible(false)}
        visible={visible}
      >
        <div className="flex flex-col justify-center">
          <Radio.Group
            onChange={(e: any) => setCategory(e.target.value)}
            value={category}
          >
            <Space direction="vertical">
              <Radio value="Offensive Content">Offensive Content</Radio>
              <Radio value="Fraud/Scam">Fraud/Scam</Radio>
              <Radio value="Duplicate Ad">Duplicate Ad</Radio>
              <Radio value="Other">Other</Radio>
            </Space>
          </Radio.Group>
          <TextArea
            showCount
            maxLength={100}
            style={{ height: 75 }}
            onChange={onChange}
            placeholder="Add comments here..."
            className="mt-3 w-full"
          />
          <AntButton
            className="mt-5 bg-red-500 text-white font-bold disabled:opacity-75"
            block
            disabled={!category || isLoading}
            onClick={handleSubmit}
          >
            SUBMIT
          </AntButton>
        </div>
      </Drawer>
    </div>
  );
};
