import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

interface IStatusMessages {
  type: null;
  message: null;
  duration: Number
}

const initialState: IStatusMessages = {
  type: null,
  message: null,
  duration: 3
};

const statusMessagesSlice = createSlice({
  name: "statusMessages",
  initialState,
  extraReducers: {},
  reducers: {
    setStatusMessage(state, { payload }) {
      return {
        ...state,
        type: payload.type,
        message: payload.message,
        duration: payload.duration || 3
      };
    },
    resetStatusMessage(state) {
      return {
        ...state,
        type: null,
        message: null,
        duration: 3
      };
    },
  },
});

export const { setStatusMessage, resetStatusMessage } =
  statusMessagesSlice.actions;

export default statusMessagesSlice.reducer;
