import AppConfig from './AppConfig';

const ServerConfig = {
  api: {
    host: AppConfig.apiHost,
    version: AppConfig.apiVersion,
    url: `${AppConfig.apiHost}${AppConfig.apiVersion}`,

    hostBrowser: AppConfig.apiHostBrowser,
    versionBrowser: AppConfig.apiVersionBrowser,
    urlBrowser: `${AppConfig.apiHostBrowser}${AppConfig.apiVersionBrowser}`,

    // Authentication
    login: "/auth/login",
    logout: "/auth/logout",
    register: "/auth/register",
    registerOAuth: "/auth/register-oauth",
    refreshTokens: "/auth/refresh-tokens",
    forgotPassword: "/auth/forgot-password",
    resetPassword: "/auth/reset-password",
    resetPasswordNoToken: "/auth/reset-password-no-token",
    generateOtp: "/auth/number-token",
    verifyOtp: "/auth/verify-number-token",

    // User Listings
    userListings: "/user-listings",
    userListingsInMonth: "/user-listings/statistics/month", /*/<userId>*/
    userListingsInDateRange: "/user-listings/statistics/date", /*/<userId>?<fromDate>&<toDate>*/

    // Categories
    allCategories: "/categories",
    categories: "/categories/active/true",

    // Filters
    filters: "/filters",

    // Listings
    listings: "/listings",
    categoryListings: "/listings/category", // <categoryId>
    searchCategoryListings: "/listings/search/category", // <categoryId>
    featuredListing: "/listings/featured/home", // <categoryId>
    carousel: "/carousel",
    related: "/listings/related",

    // Manage listings
    manageListings: "/listings/manage",
    getMultipleListings: "/listings/manage/get/many",
    renewListing: "/listings/renew",  // <listingId>

    // Search listings
    searchListings: "/listings/search",
    searchListingsMultiple: "/listings/search/multiple",

    // Notifications
    smsNotification: "/notifications/sms",
    emailNotification: "/notifications/email",
    emailToAdmin: "/notifications/email/admin",

    // Files
    files: "/files",

    // Users
    users: "/users",

    // Messages
    messages: "/messages",

    // Push notifications
    pushNotificationsToken: "/pushNotifications/token",
    pushNotifications: "/pushNotifications",

    // Chats
    chats: "/chats",
    userChats: "/chats",  /*/<userId>*/
    listingChats: "/chats/listings", /*/<listingId>/<from>/<to>*/
    listingLastChat: "/chats/listings/last", /*/<listingId>?userId=<from>*/
    updateChatMessage: "/chats/manage", /*/<chatId>*/
    
    // Chats new
    userChatList: "/chats-new/list",  // <userId>
    userListingChats: "/chats-new/listing", /*/<listingId>/<from>/<to>*/

    // App-settings
    settingsForWeb: "/app-settings/for/web",

    // Reviews
    reviews: "/reviews",
    reviewListing: "/reviews/listing",

    // User-settings
    userSettings: '/user-settings',
    
    // Frequently asked Questions
    faq: '/app/faq',

    // Report listing
    reportListing: 'app/report/listing',
    allListingReports: 'app/report/listing',
    updateListingReport: 'app/report/listing', /*/<id>*/

    // Report app issues
    reportIssue: 'app/report/app',
    updateAppReport: 'app/report/app', /*/<id>*/
    allAppReports: 'app/report/app',

    // Report User
    reportUser: 'app/report/user',
    updateUserReport: 'app/report/user/update', /*/<id>*/
    allUserReports: 'app/report/user',

    storeRating:'app/rating',
    accountVerification: '/account/resend/verify-account',

    // Public User profile
    publicUserProfile: '/users/public', /*/<userId>*/

    // Delete User Account
    deleteUserAccount: '/account/delete', /*/<userId>*/

    // Subscriptions
    subscription: '/subscriptions',

    // User Subscriptions
    userSubscriptions: '/user-subscription',
    userSubscriptionsActive: '/user-subscription/active',

    // Payment
    makePayment: '/payment',
    paymentWebhook: '/payment/stripe',

    // Admin
    activateListing: '/admin/activate-listing',
    deactivateListing: '/admin/deactivate-listing',

    // Block/unBlock
    blockUser: '/users/block',
    unblockUser: '/users/unblock',
    blockedUsers: '/users/block', /* <userId> */
  },
};

export default ServerConfig;
