import React, { useState, useEffect, createContext, useContext } from "react";
import { getSession } from "next-auth/react";
import { initConnection, initSockets } from "../sockets";

const SocketContext = createContext({  
  chatMessage: null,
  usersOnline: null,
  chatStatusSeen: null
});

const SocketProvider = (props) => {
  const [value, setValue] = useState({
    chatMessage: null,
    usersOnline: null,
    chatStatusSeen: null
  });

  useEffect(() => {
    getSession().then(res => {
      if (res?.user?.id) {
        initConnection(res.user.id);
        initSockets({
          setValue
        })
      }
    });
  }, [initSockets]);
  
  return ( 
    <SocketContext.Provider value = { value }>{ props.children }</SocketContext.Provider>
  )
};

export const SocketState = () => {
  return useContext(SocketContext);
};

export default SocketProvider;