import React, { useEffect, useState } from "react";
import { Button, Avatar } from "antd";
import { useRouter } from "next/router";
import { signOut, useSession } from "next-auth/react";
import AppConfig from "../../config/AppConfig";

const isDev = process.env.NODE_ENV === 'development';

export const MenuDrawer = () => {
  const router: any = useRouter();
  const callbackUrlAfterLogout = AppConfig.clientUrlBrowser;
  const { data: session, status } = useSession();
  const [userAvatarLetters, setUserAvatarLetters] = useState("");

  useEffect(() => {
    if (session && session.user) {
      let initials = "L";
      const nameSplit = session.user.name?.toUpperCase().split(' ') || ["L"];
      if (nameSplit.length == 1) {
        initials = nameSplit[0] ? nameSplit[0].charAt(0) : "L";
      } else {
        initials = nameSplit[0].charAt(0) + nameSplit[1].charAt(0);
      }
      setUserAvatarLetters(initials);
    }
  }, [session]);

  const handleClick = ({ key }: any) => {
    if (key === "1") {
      router.push("/userProfile");
    } else if (key === "2") {
      router.push("/chat");
      // if (session?.user && session.user?.verified) {
      //   router.push("/chat");
      // } else {
      //   message.warning("Verify your account to use this feature", 2);
      // }
    } else {
      signOut({ callbackUrl: callbackUrlAfterLogout });
      // setTimeout(router.push("/login"), 200);
    }
  };

  return (
    <>
      <Button shape="circle" className="border-0 p-0 m-0" onClick={() => router.push("/userProfile")}>
        <>
          {session?.user && (
            <Avatar
              alt={session.user.name || ""}
              size={32}
              src={session.user.image}
              style={{ lineHeight: "32px" }}
            >{userAvatarLetters}</Avatar>
          )}
        </>
      </Button>
    </>
  );
};
