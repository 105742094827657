import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { generateOtp, verifyToken, resetPassword } from "../../../services/auth.service";
import { setStatusMessage } from "../../actions";
import { ErrorCode } from "../../../utils/ErrorCode";

const initialState: any = {
  otp: {
    payload: null,
    error: null
  },
  verifyOtp: {
    payload: null,
    error: null
  },
  resetPassword: {
    payload: null,
    error: null
  }
};

export const getOtp = createAsyncThunk(
  "auth/otp",
  async (email: any, { rejectWithValue, dispatch }: any) => {
    try {
      const res = await generateOtp(email);
      dispatch(setStatusMessage({
        type: "success",
        message: ErrorCode.GENERATE_OTP_SUCCESS,
      }))
      return res.data;
    } catch (error: any) {
      dispatch(
        setStatusMessage({
          type: "error",
          message:
            error?.response?.data?.message || ErrorCode.GENERATE_OTP_FAILED,
        })
      );
      return Promise.reject(error);
      // return rejectWithValue(error); // Works. Payload will set to error
    }
  }
);

export const verifyOtp = createAsyncThunk(
  "auth/verifyOtp",
  async ({userId, otp}: any, {dispatch}:any) => {
    try {
      const res = await verifyToken(userId, otp);
      dispatch(setStatusMessage({
        type: "success",
        message: ErrorCode.VERIFY_TOKEN_SUCCESS,
      }))
      return res.data;
    } catch (error:any) {
      dispatch(
        setStatusMessage({
          type: "error",
          message:
            error?.response?.data?.message || ErrorCode.VERIFY_TOKEN_FAILED,
        })
      );
      return Promise.reject(error);
    }
  }
);

export const passwordReset = createAsyncThunk(
  "auth/passwordReset",
  async ({userId, password}: any, {dispatch}:any) => {
    try {
      const res = await resetPassword(userId, password);
      dispatch(setStatusMessage({
        type: "success",
        message: ErrorCode.RESET_PASSWORD_SUCCESS,
      }))
      return res.data;
    } catch(error: any) {
      dispatch(
        setStatusMessage({
          type: "error",
          message:
            error?.response?.data?.message || ErrorCode.RESET_PASSWORD_FAILED,
        })
      );
    }
    
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: {
    [getOtp.rejected.type]: (
      state: any, action: any
    ) => {
      return {...state, otp: { payload: null, error: action.error.message }};
    },
    [getOtp.fulfilled.type]: (
      state: any, 
      { payload }: PayloadAction<any>
    ) => {
      return {...state, otp: { payload, error: null }};
    },
    [verifyOtp.rejected.type]: (
      state: any, action: any
    ) => {
      return {...state, verifyOtp: { payload: null, error: action.error.message }};
    },
    [verifyOtp.fulfilled.type]: (
      state: any,
      { payload }: PayloadAction<any>
    ) => {
      return {...state, verifyOtp: { payload: "success", error: null }};  // No data received on success call, so "success"
    },
    [passwordReset.rejected.type]: (
      state: any, action: any
    ) => {
      return {...state, resetPassword: { payload: null, error: action.error.message}};
    },
    [passwordReset.fulfilled.type]: (
      state: any,
      { payload }: PayloadAction<any>
    ) => {
      return {...state, resetPassword: { payload: "success", error: null }};
    }
  },
  reducers: {
    resetOtp(state, { payload }) {
      return {
        ...state,
        initialState
      };
    },
  },
});

const { reducer, actions } = authSlice;

export const { resetOtp } = actions;

export default reducer;
