import axios from "axios";
import { getSession, signOut } from "next-auth/react";
import ServerConfig from "../config/ServerConfig";

// console.log(`** API: ${ServerConfig.api.hostBrowser} - ${ServerConfig.api.versionBrowser}`);

const api = axios.create({
  baseURL: `${ServerConfig.api.hostBrowser}${ServerConfig.api.versionBrowser}`,
  timeout: 10000
});

api.interceptors.request.use(async (request) => {
  const session = await getSession();
  if (session) {
    request.headers.Authorization = `Bearer ${session.tokens.access.token}`;
  }
  return request;
});

const isHandlerEnabled = (config = {}) => {
  return config.hasOwnProperty('handlerEnabled') && !config.handlerEnabled ? false : true;
};

const errorHandler = (error) => {
  if (isHandlerEnabled(error.config)) {
    console.log(`*** Error server call: ${JSON.stringify(error.config?.url)}`);

    if (!error.response) {
      return Promise.reject('Network Error')
      // return error.config;
    }

    // For timeout - error.message
    let originalRequest = error.config;

    if (error.response.status == 503) {
      console.error(`*** May be server is unreachable.`);
    }

    // If failed to get to access token, navigate to Login screen
    if (error.response.status === 401 && originalRequest.url === ServerConfig.api.refreshTokens) {
      signOut();
    }

    // Too many requests
    if (error.response.state === 429 && originalRequest.url === ServerConfig.api.refreshTokens) {
      console.error(`*** Too many requests`);
      signOut();
    }

    if (error.response.status === 401) {
      signOut();
      /*getSession().then(data => {
        if (data?.error === "SESSION_EXPIRED") {
          signOut();
        }
      });
      */
    }
  }
  return Promise.reject({ ...error });
};

const successHandler = (response) => {
  if (isHandlerEnabled(response.config)) {
    // Handle responses
    console.log(`*** Success call. ${JSON.stringify(response.config?.url)}`);
  }
  return response;
};

api.interceptors.response.use(
  response => successHandler(response),
  error => errorHandler(error)
);


export default api;